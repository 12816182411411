import axios from 'axios';
import PropTypes from 'prop-types';
import { redirectionUnauthorized } from 'ui/modules/Authentication/utils/authentication.helper';
import { isEqual } from 'lodash';

function isJson(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}

// TODO Transform this class in function to use context user
export default class DataService {
	static propTypes = {
		url: PropTypes.string.isRequired,
	};

	constructor(props) {
		this.token = JSON.parse(localStorage.getItem('token'))?.jwtToken;
		this.ogToken = JSON.parse(localStorage.getItem('token'))?.ogToken;

		this.urlParams = props.urlParams;
		this.token = {
			Authorization: `Bearer ${props.og ? this.ogToken : this.token}`,
		};
		this.retryTime = 1;
		this.counterTime = 0;
		this.noParams = props.noParams || false;
		this.failedQuery = '';
		this.statusCode = {
			badGateway: 502,
			unauthorized: 401,
			forbidden: 403,
		};
		this.url = props.url;
		this.http = axios.create({
			baseURL: props.url,
			headers: {
				'Content-Type': 'application/json',
				...this.token,
				...(props.headers || {}),
			},
		});

		this.http.interceptors.response.use(
			(response) => response,
			(error) => {
				if (!error.response) return Promise.reject(error);

				const { status } = error.response;

				this.failedQuery = isJson(error.config.data) ? JSON.parse(error.config.data) : error.config.data;

				if (status === this.statusCode.badGateway) {
					this.retryRequest(error);
				}

				if (
					status === this.statusCode.unauthorized ||
					// specific case for aws authorizer, we check the response message to be sure
					// that this is an unauthorized error
					(status === this.statusCode.forbidden && isEqual(error.response.data, { message: 'Forbidden' }))
				) {
					this.retryRequest(error);
					redirectionUnauthorized();
				}
				return Promise.reject(error);
			}
		);
	}

	retryRequest(error) {
		if (this.counterTime < this.retryTime) {
			error.config.data = this.failedQuery;
			// console.log(error.config);
			this.counterTime += 1;

			return this.http(error.config);
		}
		return false;
	}

	getRequestService() {
		return this.http;
	}

	// SearchQuery
	getAlls(data) {
		return this.http.post(`${this.urlParams}`, data);
	}

	getAll() {
		return this.http.get(this.urlParams);
	}

	get(id) {
		return this.http.get(this.noParams ? `/${id}` : `/${id}/${this.urlParams}`);
	}

	getAllWithParamObj() {
		if (typeof this.urlParams === 'object' && this.http.defaults?.baseURL)
			return this.http.get(this.http.defaults.baseURL, {
				params: {
					...this.urlParams,
				},
			});
		return this.http.get(this.urlParams);
	}

	getOneWithParamObj(id) {
		if (typeof this.urlParams === 'object' && this.http.defaults?.baseURL)
			return this.http.get(`${this.http.defaults.baseURL}/${id}`, {
				params: {
					...this.urlParams,
				},
			});
		return this.http.get(this.urlParams);
	}

	getClean(url) {
		if (url) return this.http.get(url, { params: this.urlParams });
		return this.http.get();
	}

	// TODO: must delete create method => we have to use post instead of it
	create(data) {
		return this.http.post(`${this.urlParams}`, data);
	}

	post(data) {
		return this.http.post(`${this.urlParams || ''}`, data);
	}

	update(id, data) {
		return this.http.put(`/${id}${this.urlParams || ''}`, data);
	}

	put(data) {
		return this.http.put(`${this.urlParams || ''}`, data);
	}

	patch(data) {
		return this.http.patch(this.urlParams, data);
	}

	delete(id) {
		return this.http.delete(`/${id}${this.urlParams}`);
	}

	deleteMultiple() {
		return this.http.delete(this.urlParams);
	}

	deleteAll() {
		return this.http.delete(this.path);
	}
}
