import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'antd';
import BulkExecuteFreightRate from './BulkExecuteFreightRate';
import BulkExecutionWotoPayload from './BulkExecutionWotoPayload';
import BulkExecutionWotoEmail from './BulkExecutionWotoEmail';
import BulkExecuteLineupConsolidation from './BulkExecuteLineupConsolidation';

function BulkExecuteModal(props) {
	const { visible, records = [], model, onOk = () => {}, onCancel = () => {} } = props;

	const renderByModel = () => {
		switch (model) {
			case 'freight_rate':
				return <BulkExecuteFreightRate records={records} onCancel={onCancel} onEndExecution={onOk} />;
			case 'woto_payloads':
				return (
					<BulkExecutionWotoPayload
						key={records.map((c) => c.created_at).join(',')}
						records={records}
						onCancel={onCancel}
						onEndExecution={onOk}
					/>
				);
			case 'woto_emails':
				return (
					<BulkExecutionWotoEmail
						key={records.map((c) => c.email_id).join(',')}
						records={records}
						onCancel={onCancel}
						onEndExecution={onOk}
					/>
				);
			case 'lineup_consolidation':
				return <BulkExecuteLineupConsolidation records={records} onCancel={onCancel} onEndExecution={onOk} />;

			default:
				return null;
		}
	};

	return (
		<Modal title="Bulk Execution" open={visible} width="90%" footer={null} onCancel={onCancel}>
			{renderByModel()}
		</Modal>
	);
}
BulkExecuteModal.propTypes = {
	model: PropTypes.oneOf(['freight_rate', 'woto_payloads', 'woto_emails', 'lineup_consolidation']).isRequired,
	records: PropTypes.arrayOf(PropTypes.object).isRequired,
	onOk: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	visible: PropTypes.bool,
};
export default BulkExecuteModal;
