import { createContext, useContext } from 'react';

const modalContext = createContext({
	modals: [],
	pushModal: () => {},
	popModal: () => {},
	replaceModal: () => {},
});

export function useModalContext() {
	return useContext(modalContext);
}

export default modalContext;
