// config
import config from 'src/config';
// utils
import DataService from 'src/utils/DataService';

/**
 * get email list
 * @returns
 */
export function getListEmails() {
	const split = config.api.split || 3000;
	return new DataService({
		url: `${config.api.emailUrl}?limit=${split}&offset=${0}`,
		urlParams: '',
		token: true,
	});
}

/**
 * get email details
 * @param id
 * @returns
 */
export const getEmail = async (id: string) => {
	return await new DataService({
		url: `${config.api.emailUrl}/${id}?isDeep=true`,
		urlParams: '',
		token: true,
	}).getClean();
};

/**
 * email extractor
 * @param payload
 */
export const emailExtractor = async (payload: any) => {
	return await new DataService({
		url: `${config.api.emailUrl}/extractor`,
		urlParams: '',
	}).create(payload);
};

/**
 * bulk delete email
 * @param payload
 * @returns
 */
export const bulkDeleteEmail = async (payload: { ids: string[] }) => {
	return await new DataService({
		url: `${config.api.emailUrl}/delete`,
		urlParams: '',
	}).post(payload);
};
