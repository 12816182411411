export default {
	name: 'pipeline',
	name_plural: 'pipelines',
	new_record_title: 'New pipeline',
	actions: {
		create_new: 'Create a new pipeline',
		add_scheduler: 'Add a new schedule',
	},
	placeholders: {
		select_tz: 'Select a timezone',
	},
};
