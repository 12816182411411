import { uniqBy } from 'lodash';
// utils
import DataService from 'src/utils/DataService';
// configs
import config from 'src/config';
import datasetConfig from 'ui/modules/Core/config/dataset.config';
import { data, getApiPrefix, server } from 'src/utils/api-prefix.helper';
//interface
import { IDataset, IPreviewPayload } from 'ui/modules/Core/types/interfaces/dataset.interface';
// constants
const serverUrl = `${server}${getApiPrefix()}/`;

export function updateDatasetService(code: string, payload: any) {
	return new DataService({
		url: datasetConfig.url,
		urlParams: '',
	}).update(code, payload);
}

export function createDatasetService(payload: any) {
	return new DataService({
		url: datasetConfig.url,
		urlParams: '',
	}).create(payload);
}

export function cloneDatasetService(dataset_code: string, payload: any) {
	return new DataService({
		url: `${datasetConfig.url}/${dataset_code}/clone`,
		urlParams: '',
	}).create(payload);
}

export function getDatasetService(params: string | null = null) {
	return new DataService({
		url: `${datasetConfig.url}?${params || datasetConfig.urlParams}`,
		urlParams: '',
		token: true,
	});
}

export async function getAvailableDatasetsService() {
	const res = await getDatasetService('relation=[source,category]&versioned=true').getAll();
	const datasets: IDataset[] = res.data?.result || [];
	return uniqBy(datasets, 'code').map((el) => ({
		id: el.id,
		title: el.name,
		code: el.code,
		source: el.source,
		category: el.category,
	}));
}

export function getDatasetServiceByCode(dataset_code: string) {
	return new DataService({
		url: `${datasetConfig.url}/${dataset_code}?${datasetConfig.urlOneDatasetParams}`,
		urlParams: '',
	});
}

export function deleteDatasetServiceByCode(code: string) {
	return new DataService({
		url: datasetConfig.url,
		urlParams: '',
	}).delete(code);
}

export function getDataSharingServiceById(dataset_id: string) {
	return new DataService({
		url: `${config.api.data_sharing.url}/${dataset_id}?relation=[dataset]`,
		urlParams: '',
	});
}

export function getAllUsageService() {
	return new DataService({
		url: `${datasetConfig.url}/*/usage`,
	});
}

export function getDatasetUsageService(dataset_code: string) {
	return new DataService({
		url: `${datasetConfig.url}/${dataset_code}/usage`,
	});
}

export function getStatisticService(dataset_code: string) {
	return new DataService({
		url: `${config.api.ingestWorkflowUrl}/dataset/${dataset_code}/stats`,
		urlParams: '',
	});
}

export function onEmptyDatasetService(dataset_code: string) {
	return new DataService({
		url: `${serverUrl}${data}/datasets/${dataset_code}/empty`,
		urlParams: '',
	});
}

export function searchService(type: string) {
	return new DataService({
		url: config.api.searchUrl,
		urlParams: `/${type}?limit=10000`,
	});
}

export function getQueryDataWarehouseService() {
	return new DataService({
		url: `${config.api.clientUrl}/dataset-query`,
		urlParams: '',
	});
}

export function getDatasetShareService() {
	return new DataService({
		url: `${config.apiGateway.REST_URL}/dataset_share`,
		urlParams: '',
	});
}

export const bulkDeleteDataset = async (payload: { codes: string[] }) => {
	return await new DataService({
		url: `${datasetConfig.url}/delete`,
		urlParams: '',
	}).post(payload);
};

export const getDatasetPreview = async (code: string, payload: IPreviewPayload) => {
	const result = await new DataService({
		url: `${serverUrl}${data}/datasets/${code}/preview`,
		urlParams: '',
	}).post(payload);
	return result.data || [];
};
