import _ from 'lodash';
import DataService from 'src/utils/DataService';
import config from 'src/config';
import configWorkflow from 'ui/modules/Core/config/workflow.config';
import { data, getApiPrefix, ingestWorkflow, server } from 'src/utils/api-prefix.helper';
import { IPreviewPayload } from '../types/interfaces/dataset.interface';
// constants
const serverUrl = `${server}${getApiPrefix()}/`;

export function getWorkflowNewService() {
	const split = config.api.split || 3000;
	return new DataService({
		url: `${configWorkflow.url}?limit=${split}&offset=${0}`,
		urlParams: '',
		token: true,
	});
}

export function getWorkflowService(params: string) {
	return new DataService({
		url: configWorkflow.oldUrl,
		urlParams: params,
		token: true,
	});
}
export function getOneWorkflow(params: string, workflow_id: string) {
	return new DataService({
		url: `${configWorkflow.url}/${workflow_id}${params}`,
		urlParams: '',
		token: true,
	});
}

export function getPreviewStepService() {
	return new DataService({
		url: `${config.api.clientUrl}/step-preview`,
		urlParams: '',
	});
}

export function selectPayloadService(datasetCode: string, type: string, date: string) {
	return new DataService({
		url: `${config.api.ingestWorkflowUrl}/dataset/${datasetCode}/select-payload/${type}/${date}`,
		urlParams: '',
	});
}

export function loadPendingPayloadService(datasetCode: string) {
	return new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/datasets/${datasetCode}/history`,
		urlParams: '',
	});
}

export function loadWorkflowExecutionStateService(datasetCode: string) {
	return new DataService({
		url: `${config.api.ingestWorkflowUrl}/dataset/${datasetCode}/execution-state`,
		urlParams: '',
	});
}

export function executeEmailMatchService(datasetCode: string) {
	return new DataService({
		url: `${serverUrl}${ingestWorkflow}/datasets/${datasetCode}/matching-emails/search`,
		urlParams: '',
	});
}

export function subscribeWorkflowService(datasetCode: string) {
	return new DataService({
		url: `${config.api.ingestWorkflowUrl}/dataset/${datasetCode}/subscribe`,
		urlParams: '',
	});
}

/**
 * getWorkflowSettingService
 * @param newWorkflowID
 * @param datasetCode
 * @returns
 */
export function endWorkflowExecutionService(datasetCode: string, payload: { workflow_id: string }) {
	return new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/datasets/${datasetCode}/stop`,
		urlParams: '',
	}).create(payload);
}

export function executeWorkflowService() {
	return new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/execute`,
		urlParams: '',
	});
}

export function retrieveIssuesService(datasetCode: string, step: string) {
	return new DataService({
		url: `${config.api.ingestWorkflowUrl}/preview-error/${datasetCode}/${step}`,
		urlParams: '',
	});
}

/**
 * getWorkflowSettingService
 * @param newWorkflowID
 * @returns
 */
export function getWorkflowSettingService(workflowId: string, payload: any = null) {
	if (payload) {
		return new DataService({
			url: `${configWorkflow.ingestWorkflowUrl}/settings/${workflowId}`,
			urlParams: '',
		}).create(payload);
	} else {
		return new DataService({
			url: `${configWorkflow.ingestWorkflowUrl}/settings`,
			urlParams: '',
		}).getClean(workflowId);
	}
}

/**
 * discardDatasetService
 * @param datasetCode
 * @param date
 * @returns
 */
export function discardDatasetService(datasetCode: string, date: string) {
	return new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/datasets/${datasetCode}/payloads/${date}/discard`,
		urlParams: '',
	}).deleteAll();
}

/**
 * get workflow preview steps
 * @param datasetCode
 * @param step
 * @param payload
 * @returns
 */
export function getWorkflowPreviewSteps(datasetCode: string, step: string, payload: IPreviewPayload) {
	return new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/datasets/${datasetCode}/steps/${step}/preview`,
		urlParams: '',
	}).create(payload);
}

/**
 * loadHistoryDataService
 * @param payload
 * @returns
 */
export function loadHistoryDataService(payload: any) {
	return new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/workflow-loader-history`,
		urlParams: '',
	}).create(payload);
}

/**
 * downloadPayload
 * @param url
 * @returns
 */
export function downloadPayload(payloadKey: string, format: string) {
	return new DataService({
		url: `${serverUrl}${data}`,
		urlParams: !format ? payloadKey : `${payloadKey}?format=${format}`,
	}).getAll();
}

/**
 * get workflow script service
 * @param script_id
 * @param path
 * @returns
 */
export const getWorkflowScriptService = async (script_id: string, path: string) => {
	return new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/script/${script_id}`,
		urlParams: '',
		token: true,
	}).create({ path });
};

type IExecuteOneEmailBody = {
	email_attachment_id: string;
	email_id: string;
};
/**
 * scheduleWorkflowImmediately
 * @param type
 * @param workflow_id
 * @returns
 */
export function scheduleWorkflowImmediately(type: string, workflow_id: string, body: IExecuteOneEmailBody | null) {
	const url = `${configWorkflow.ingestWorkflowUrl}/${
		type === 'email' ? `workflows/${workflow_id}/replay-last-email` : 'schedule-immediately'
	}`;
	const payload = type === 'email' ? { workflow_id, ...(body || {}) } : { id: workflow_id };

	return new DataService({ urlParams: [] }).getRequestService().post(url, payload);
}

/**
 * execute all matching workflows (email)
 * @param payload
 * @returns
 */
export const executeAllMatchingWorkflowsService = async (payload: any) => {
	return await new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/replay-email`,
		urlParams: '',
	}).post(payload);
};

/**
 * execute workflow on attachment
 * @param payload
 * @returns
 */
export const executeWorkflowOnAttachmentService = async (payload: any) => {
	return await new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/execute-workflow-on-attachment`,
		urlParams: '',
	}).create(payload);
};

/**
 * getWorkflowMetadataService
 * @param workflow_id
 * @returns
 */
export const getWorkflowMetadataService = async (workflow_id: string) => {
	return await new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/workflows/${workflow_id}/metadata`,
		urlParams: '',
	}).getAll();
};

export const saveWorkflowMetaDataService = async (workflow_id: string, payload: any) => {
	return await new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/workflows/${workflow_id}/metadata`,
		urlParams: '',
	}).create(payload);
};

// TODO: migration
export async function getDatasetsFromType(type: string) {
	return await new DataService({
		url: config.api.searchUrl,
		urlParams: '/category?relation=[category_fields,parent]',
	}).getAlls({
		category_fields: {
			where: `type eq ${type?.toLowerCase()}`,
		},
		parent: {
			where: 'code eq MAP',
		},
	});
}

export async function getDatasetsFromAvailableTypes(ids: string) {
	const result = await new DataService({
		url: config.api.searchUrl,
		urlParams: '/dataset?relation=[category]',
	}).getAlls({
		where: `category_id in (${ids})`,
	});

	return result.data;
}

export function ignoreHealthcheckViolations(id: string) {
	return new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/workflows/${id}/ignore-healthcheck`,
		urlParams: '',
		token: true,
	}).put();
}
