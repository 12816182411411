// Dependencies
import React, { Component } from 'react';
// shared utils
import { map, split, capitalize } from 'lodash';
// ant design
import { Space, Tag } from 'antd';
import config from 'src/config';

export default class tagRenderer extends Component {
	render() {
		let value = this.props.value || this.props.null_value;
		if (!value) return null;
		value = split(value, this.props.colDef?.tag_separator || ',');
		const mapping = {
			...config.labels.system,
			...config.labels.dataset,
			...config.labels.trade_flow,
			...config.labels.data_prep,
		};
		return map(value, (tag, index) => {
			if (tag === '_') return tag;
			return (
				<Space wrap key={index}>
					<Tag
						style={{ textAlign: 'center' }}
						color={mapping && mapping[tag] ? mapping[tag] : this.props.color}
					>
						{capitalize(tag.replace('_', ' '))}
					</Tag>
				</Space>
			);
		});
	}
}
