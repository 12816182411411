export default {
	name: 'List',
	name_plural: 'Lists',
	search: 'Search...',
	refresh_list: 'Refresh list',
	clean_filters: 'Clear filters',
	search_by: 'Search by ',
	rows_per_page: 'Rows per page',
	export_excel: 'Export to Excel',
	select_columns: 'Select columns',
	errors: {
		delete_error: 'An error occurred when deleting record(s).',
		delete_failed: 'A problem occurred while deleting record(s). Please try again later',
	},
	success: {
		delete_success: 'Selected record(s) have been successfully deleted.',
	},
};
