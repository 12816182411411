import { getApiPrefix, server, referenceData } from 'src/utils/api-prefix.helper';
// constants
const referenceDataUrl = `${server}${getApiPrefix()}/${referenceData}/`;

export default {
	referenceRecord: 'custom-code',
	referenceData: false,
	is_not_linked: true,
	groupOf: 'custom-code',
	url: `${referenceDataUrl}custom-code`,
	urlParams: 'relation=[user,direct_parent]',
	path: '/custom-codes',
	navigateByCode: true,
	pageTitle: {
		list: 'Custom codes',
		action: {
			create: 'New custom code',
			edit: 'Edit custom code',
		},
	},
};
