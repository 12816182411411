// Shared helpers
import { isNil, isEmpty, split } from 'lodash';

export default {
	isEmpty: {
		displayKey: 'isEmpty',
		displayName: 'Is empty',
		predicate: (filterValue, cellValue) => isNil(cellValue) || isEmpty(cellValue),
		hideFilterInput: true,
	},
	isNotEmpty: {
		displayKey: 'isNotEmpty',
		displayName: 'Is not empty',
		predicate: (filterValue, cellValue) => !isNil(cellValue) && !isEmpty(cellValue),
		hideFilterInput: true,
	},
	containsEach: {
		displayKey: 'containsEach',
		displayName: 'Contains each',
		predicate: (filterValue, cellValue) => {
			if (cellValue) {
				const filterValues = split(filterValue, ' ');
				let check = true;
				for (let i = 0; i < filterValues.length; i++) {
					if (!filterValues[i]) continue;
					filterValues[i] = filterValues[i].replaceAll('-', '');
					check = check && cellValue.includes(filterValues[i].toLowerCase());
				}

				return check;
			}
		},
	},
};
