import { faEnvelope } from '@fortawesome/pro-light-svg-icons';
// constants
import { emailRulesetColumns } from 'src/ui/modules/Storage/constants/emailRuleset.constant';
// utils
import _ from 'lodash';
import { server, storage, getApiPrefix } from 'src/utils/api-prefix.helper';
// Services
import { bulkDeleteEmailRuleset, getEmailRulesetsService } from 'src/ui/modules/Storage/services/emailRuleset.service';

export default {
	referenceData: false,
	iconName: faEnvelope,
	url: `${server}${getApiPrefix()}/${storage}/email-ruleset`,
	recordid: 'email_ruleset_id',
	paramUrl: '/email-ruleset',
	path: '/email-ruleset',
	urlParams: 'relation=[email-ruleset-rule]&limit=1000',
	pageTitle: {
		list: 'Emails ruleset',
		action: {
			create: 'New email ruleset',
			edit: 'Edit email ruleset',
		},
	},
	columns: emailRulesetColumns,
	duplicateRelation: '?relation=[email_ruleset_rule]',
	useTab: 'general',
	getService: () => ({
		instance: getEmailRulesetsService,
		bulkDelete: bulkDeleteEmailRuleset,
	}),
};
