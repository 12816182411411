import { faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons';
import { getApiPrefix, referenceData, server } from 'src/utils/api-prefix.helper';
// constants
const referenceDataUrl = `${server}${getApiPrefix()}/${referenceData}/`;

export default {
	referenceRecord: 'location',
	is_mixed: true,
	referenceData: false,
	iconName: faMapMarkerAlt,
	url: `${referenceDataUrl}location?params=is_mixed`,
	navigateByCode: true,
	path: '/locations',
	urlParams: '',
	parentField: 'parent_id',
	pageTitle: {
		list: 'Locations and groups',
		action: {
			create: 'New location and group',
			edit: 'Edit location and group',
		},
	},
};
