import DataService from 'utils/DataService';
import { convertData } from 'utils/ConvertData';

import Axios from 'axios';
import { saveAs } from 'file-saver';

import { sleep } from 'utils/Validator';

import { orderBy, uniqBy, isEmpty, isNil } from 'lodash';

import config from 'config';

export const getPreviewDataset = async (code) => {
	return await new DataService({
		url: `${config.api.clientUrl}/preview`,
		urlParams: '',
	}).create({
		dataset_code: code,
	});
};

// TODO: remove it we have to use the split service
export const getProducts = async (parent = false, convert = true) => {
	const data = [];
	const result = await new DataService({
		url: config.records.product.url,
		urlParams: `?limit=10000&order=[["name", "asc"]]${parent ? `&relation=[parent]` : ''}`,
	}).getAll();

	result.data.map((el) => {
		data.push({
			id: el.id,
			key: el.id,
			title: el.name,
			description: el.name,
			code: el.code,
			pId: el.parent_id,
			text: el.name,
			isGroup: el.is_group,
		});
	});
	return orderBy(convert ? convertData(data) : data, 'parent', 'asc');
};

// TODO: remove it we have to use the split service
export const getUnits = async (parent = false, convert = true) => {
	const data = [];
	const result = await new DataService({
		url: config.records.unit.url,
		urlParams: `?limit=10000&order=[["name", "asc"]]${parent ? `&relation=[parent]` : ''}`,
	}).getAll();

	result.data.map((d) => {
		let label = d.name;
		if (d.code) label += ` (${d.code})`;
		data.push({
			label,
			id: d.id,
			value: d.id,
			pId: null,
			text: label,
			isGroup: d.is_group,
			code: d.code,
		});
	});

	return orderBy(data, 'name', 'asc');
};

export const getTradeflowMode = async (filter = false) => {
	let data = [];
	const result = await new DataService({
		url: config.records.trade_flow_mode.url,
		urlParams: '?limit=10000&order=[["name", "asc"]]',
	}).getAll();
	result.data.map((d) => {
		let label = d.name;
		if (d.code) label += ` (${d.code})`;
		data.push({
			label,
			id: d.id,
			value: d.id,
			pId: null,
			text: label,
			isGroup: d.is_group,
			code: d.code,
		});
	});
	if (Array.isArray(filter)) data = data.filter((x) => filter.includes(x.code));

	return orderBy(data, 'name', 'asc');
};

// TODO: remove it we have to use the split service
export const getSndItems = async (filter = false) => {
	let data = [];
	const result = await new DataService({
		url: config.records.snd_item.url,
		urlParams: '?limit=10000&order=[["name", "asc"]]',
	}).getAll();
	result.data.map((d) => {
		let label = d.name;
		if (d.code) label += ` (${d.code})`;
		data.push({
			label,
			id: d.id,
			value: d.id,
			pId: null,
			text: label,
			isGroup: d.is_group,
			code: d.code,
			order: label,
			name: d.name,
		});
	});
	if (Array.isArray(filter)) data = data.filter((x) => filter.includes(x.code));

	return orderBy(data, 'name', 'asc');
};

export const loadBatchLocation = async (size, offset) => {
	const result = await new DataService({
		url: config.records.location.url,
		urlParams: `?limit=${size}&offset=${offset}&order=[["name", "asc"]]`,
	}).getAll();

	return result.data.map((el) => ({
		id: el.id,
		key: el.id,
		title: el.name,
		description: el.name,
		code: el.code,
		pId: el.parent_id,
		text: el.name,
		isGroup: el.is_group,
		level_name: el.level_name,
	}));
};

// TODO: remove it we have to use the split service
export const getLocations = async () => {
	const data = [];
	for (let i = 1; i <= 3; i++) {
		data.push(await loadBatchLocation(3000, (i - 1) * 3000));
	}

	return orderBy(convertData(data.flat()), 'parent', 'asc');
};

export const getAvailableDatasets = async (categories = null, selectFormat = false) => {
	const closeSearch = {
		where: 'grade ne raw and current_dataset_version ne null',
	};
	if (!isNil(categories) && !isEmpty(categories)) {
		closeSearch.category = { where: `code in (${categories.join(',')})` };
	}

	const datasets = await Promise.all([
		new DataService({
			url: `${config.api.searchUrl}/dataset`,
			urlParams: `?relation=[category, ${selectFormat ? '' : 'location,'} source]&limit=10000`,
		}).getAlls(closeSearch),
		new DataService({
			url: `${config.api.baseUrl}data_sharing`,
			token: true,
			urlParams: '?relation=[dataset]',
		}).getAll(),
	]);

	const data = [];
	datasets.map((x, i) => {
		if (i === 1)
			data.push(
				x.data
					.map((c) => c.dataset)
					.filter((c) =>
						Array.isArray(categories) && c?.category ? categories.includes(c.category?.code) : true
					)
			);
		else data.push(x.data);
	});

	if (selectFormat) {
		return uniqBy(data.flat(), 'code').map((el) => ({
			id: el.id,
			title: el.name,
			code: el.code,
			source: el.source,
			category: el.category,
		}));
	}

	return uniqBy(data.flat(), 'code');
};

export const getAvailableTradeflows = async (id, selectFormat = false) => {
	const tfs = await Promise.all([
		new DataService({
			url: `${config.api.baseUrl}/trade-flow`,
			urlParams: '',
		}).getAll(),
		new DataService({
			url: `${config.api.baseUrl}data_sharing`,
			token: true,
			urlParams: '?relation=[trade_flow]',
		}).getAll(),
	]);
	const data = [];
	tfs.map((x, i) => {
		if (i === 1) data.push(x.data.map((c) => c.trade_flow));
		else data.push(x.data.filter((c) => c.id !== id));
	});

	if (selectFormat) {
		return uniqBy(data.flat(), 'code').map((el) => ({
			id: el.id,
			key: el.id,
			title: el.name,
			description: el.name,
			code: el.code,
			parent: el.parent ? el.parent.name : null,
		}));
	}

	return uniqBy(data.flat(), 'code');
};
export const getAvailableProductions = async (id, selectFormat = false) => {
	const tfs = await Promise.all([
		new DataService({
			url: `${config.api.baseUrl}/production`,
			urlParams: '',
		}).getAll(),
		new DataService({
			url: `${config.api.baseUrl}data_sharing`,
			token: true,
			urlParams: '?relation=[production]',
		}).getAll(),
	]);
	const data = [];
	tfs.map((x, i) => {
		if (i === 1) data.push(x.data.map((c) => c.production));
		else data.push(x.data.filter((c) => c.id !== id));
	});

	if (selectFormat) {
		return uniqBy(data.flat(), 'code').map((el) => ({
			id: el.id,
			key: el.id,
			title: el.name,
			description: el.name,
			code: el.code,
			parent: el.parent ? el.parent.name : null,
		}));
	}

	return uniqBy(data.flat(), 'code');
};

export const getAvailableForecasts = async (id, selectFormat = false) => {
	const tfs = await Promise.all([
		new DataService({
			url: `${config.api.baseUrl}/forecast`,
			urlParams: '',
		}).getAll(),
		new DataService({
			url: `${config.api.baseUrl}data_sharing`,
			token: true,
			urlParams: '?relation=[forecast]',
		}).getAll(),
	]);
	const data = [];
	tfs.map((x, i) => {
		if (i === 1) data.push(x.data.map((c) => c.forecast));
		else data.push(x.data.filter((c) => c.id !== id));
	});

	if (selectFormat) {
		return uniqBy(data.flat(), 'code').map((el) => ({
			id: el.id,
			key: el.id,
			title: el.name,
			description: el.name,
			code: el.code,
			type: el.type,
			parent: el.parent ? el.parent.name : null,
		}));
	}
	return uniqBy(data.flat(), 'code');
};

export const getCategoryFieldsById = async (id) => {
	const result = await new DataService({
		url: `${config.api.baseUrl}category`,
		urlParams: '?relation=[category_fields]',
		token: true,
	}).get(id);

	return result?.data?.category_fields || [];
};

export const getCategoryFieldsByCode = async (code) => {
	const result = await new DataService({
		url: `${config.api.searchUrl}/category`,
		urlParams: '?relation=[category_fields]&limit=10000',
	}).getAlls({
		where: 'code eq ' + code,
	});

	return result?.data[0]?.category_fields || [];
};

export const getDatasetFieldsByCode = async (id) => {
	const result = await new DataService({
		url: `${config.api.baseUrl}/dataset`,
		urlParams: '?relation=[dataset_field]&limit=10000',
	}).get(id);

	return result?.data?.dataset_field || [];
};

export const getCategories = async () => {
	const result = await new DataService({
		url: `${config.api.baseUrl}category`,
		urlParams: '?relation=[category_fields]&limit=10000',
	}).getAll();

	return result?.data || [];
};

export const getLastUpdateDatasets = async (code) => {
	const result = await new DataService({
		url: `${config.api.base}dev/data/datasets/${code ? code : '*'}/last-update`,
		urlParams: '',
		token: true,
	}).create();

	return result?.data?.rows || [];
};

export const getESDatasets = (filters = []) =>
	new Promise((resolve) => {
		new DataService({
			url: `${config.api.catalogUrl}/private`,
			urlParams: '',
		})
			.post({
				searchTerm: null,
				from: 0,
				size: 100,
				filters,
				sort: '_score',
				sortDirection: 'asc',
			})
			.then((resp) => {
				resolve({
					type: 'ES',
					success: true,
					count_all: resp?.data?.result?.hits?.total?.value || 0,
					data: (resp?.data?.result?.hits?.hits || []).map((ds) => ({
						id: ds._id,
						code: ds._id,
						...ds._source,
					})),
				});
			})
			.catch((error) => resolve({ type: 'ES', success: false, error }));
	});

export const downloadDataset = async (code, version_id = null) => {
	try {
		const result = await new DataService({
			url: `${config.api.dataUrl}/datasets/${code}/download${version_id ? '?version_id=' + version_id : ''}`,
			urlParams: '',
			token: true,
		}).create();

		const fileUrl = result.data.result.url;
		const task_id = result.data.task.id;
		let task = null;
		let counter = 0;

		while (!task && counter < 600) {
			await sleep(2000);
			let status = await getTaskStatus(fileUrl, task_id);
			if (status === 'SUCCEEDED') task = true;
			if (status === 'FAILED' || status === 'ABORTED') throw 'error_trying_reaching_resource';
			counter += 5;
		}

		if (task) {
			const file = await Axios.get(fileUrl, {
				responseType: 'blob', // Important
			});

			const blob = file.data;

			//const blob = new Blob([file.data], { type: "text/plain;charset=utf-8" });

			saveAs(blob, `${code}.${version_id ? 'parquet' : 'csv'}`);

			return true;
		}
	} catch (err) {
		return null;
	}
};

function jsonToCsv(data) {
	let array = data;
	if (!Array.isArray(data)) {
		data = data.replace(/NaN/g, 'null');
		try {
			array = JSON.parse(data);
		} catch (e) {
			// trying to parse list of JSON objects instead
			const preparedData = data
				.split('\n')
				.map((str) => (str || '').trim())
				.filter(Boolean)
				.join(',');
			array = JSON.parse(`[${preparedData}]`);
		}
	}
	let csv = '';
	const keys = Object.keys(array[0]);
	csv += keys.join(',') + '\n';

	array.forEach((row) => {
		csv += keys.map((key) => row[key]).join(',') + '\n';
	});

	return csv;
}

export const downloadFileByUrlAsCSV = async (data) => {
	const file = await Axios.get(data.url);
	const csvData = jsonToCsv(file.data);
	const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
	saveAs(blob, `${data.name}.csv`);
};

export const downloadFile = async ({ url, name } = {}) => {
	// trigger download of URL in browser
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', `${name}`);
	document.body.appendChild(link);
	link.click();
	link.remove();
};

export const downloadProduction = async (code, refresh = false, format = 'csv', rest = {}) => {
	try {
		let result = await new DataService({
			url: config.api.dev.production_api + `/${code}/download`,
			urlParams: '',
			token: true,
		}).create({
			refresh: !refresh,
			...rest,
			format: format,
		});

		const fileUrl = result.data.result.url;
		const task_id = result.data.task.id;
		let task = null;
		let counter = 0;

		while (!task && counter < 600) {
			await sleep(2000);
			let status = await getTaskStatus(fileUrl, task_id);
			if (status === 'SUCCEEDED') task = true;
			if (status === 'FAILED') throw 'error_trying_reaching_resource';
			counter += 5;
		}

		if (task) {
			const file = await Axios.get(fileUrl, {
				responseType: 'blob', // Important
			});

			const blob = file.data;

			//const blob = new Blob([file.data], { type: "text/plain;charset=utf-8" });

			saveAs(blob, `${code}.csv`);

			return true;
		}
	} catch (err) {
		console.log('err', err);
		return null;
	}
};
export const downloadTradeflow = async (
	code,
	aggregated = false,
	refresh = false,
	intradeflow = false,
	row = false,
	format = 'csv',
	rest = {}
) => {
	try {
		let result = await new DataService({
			url: config.api.tradeflow_api + `${aggregated ? '/aggregations' : ''}/${code}/download`,
			urlParams: '',
			token: true,
		}).create({
			intraflows: {
				strategy: intradeflow ? 'include' : 'exclude',
			},
			row: row,
			refresh: !refresh,
			...rest,
			format: format,
		});

		const fileUrl = result.data.result.url;
		const task_id = result.data.task.id;
		let task = null;
		let counter = 0;

		while (!task && counter < 600) {
			await sleep(2000);
			let status = await getTaskStatus(fileUrl, task_id);
			if (status === 'SUCCEEDED') task = true;
			if (status === 'FAILED') throw 'error_trying_reaching_resource';
			counter += 5;
		}

		if (task) {
			const file = await Axios.get(fileUrl, {
				responseType: 'blob', // Important
			});

			const blob = file.data;

			//const blob = new Blob([file.data], { type: "text/plain;charset=utf-8" });

			saveAs(blob, `${code}.csv`);

			return true;
		}
	} catch (err) {
		console.log('err', err);
		return null;
	}
};

export const getTaskStatus = async (urlTask, task_id, headers) => {
	const taskStatus = await new DataService({
		url: config.api.taskUrl,
		urlParams: null,
		token: true,
		noParams: true,
	}).get(task_id);

	return taskStatus.data.status;
};
