import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
// ant design & Icons
import { faBox } from '@fortawesome/pro-light-svg-icons';
//Components
import ReferenceField from 'ui/components/ReferenceField/ReferenceField';
// utils
import { referenceDataUrl } from 'src/utils/api-prefix.helper';

export default {
	referenceRecord: 'product',
	referenceData: true,
	navigateByCode: true,
	iconName: faBox,
	url: `${referenceDataUrl}product`,
	urlParams: '',
	relatedReferences: ['product-category'],
	keysMatching: [{ key: 'product_category', type: 'product-category' }],
	path: '/products',
	elasticSearchKey: 'Product',
	pageTitle: {
		list: 'Products',
		action: {
			create: 'New product',
			edit: 'Edit product',
		},
	},
	columns: [
		{
			headerName: 'Inactive',
			field: 'is_archived',
			type: 'boolean',
			initialHide: true,
		},
		{
			headerName: 'Code',
			field: 'code',
		},
		{
			headerName: 'Name',
			field: 'name',
		},
		{
			headerName: 'Parent',
			field: 'direct_parent',
			valueGetter: ({ data }: any) => data.direct_parent?.name || data.direct_parent?.code,
			cellRenderer: ({ data }: ICellRendererParams) => {
				return data.direct_parent ? (
					<ReferenceField
						disabled={data.direct_parent.deleted_at}
						value={data.direct_parent.name}
						url={`/${data.direct_parent.is_group ? 'products-group' : 'products'}/${
							data.direct_parent.code
						}`}
					/>
				) : (
					'_'
				);
			},
		},
		{
			headerName: 'Product categories',
			field: 'relations',
			cellRenderer: ({ data }: ICellRendererParams) => {
				return (data.relations || []).map(
					(reference: { name: string; code: string; deleted_at: string }, i: number) => (
						<ReferenceField
							key={`reference${i}`}
							disabled={reference.deleted_at}
							value={reference.name}
							url={`/product-categories/${reference.code}`}
						/>
					)
				);
			},
		},
		{
			headerName: 'Created by',
			minWidth: 150,
			field: 'created_by_user',
			type: 'reference',
			referenceType: 'user',
		},
		{
			headerName: 'Created',
			field: 'created_at',
			type: 'datetime',
		},
		{
			headerName: 'Updated',
			field: 'updated_at',
			type: 'datetime',
		},
	],
	canCreate: true,
	canEdit: true,
	canDelete: true,
	canArchive: true,
	parentField: 'parent_id',
};
