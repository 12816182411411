import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisHAlt, faSquare } from '@fortawesome/pro-light-svg-icons';
import { faCircle, faPause, faStop } from '@fortawesome/free-solid-svg-icons';
// helpers
import _, { min } from 'lodash';
import { pushNotification } from 'src/utils/PushNotification';
import { basePath, data, getApiPrefix, server, ingestWorkflow } from 'src/utils/api-prefix.helper';
// services
import { getWorkflowNewService } from 'ui/modules/Core/services/workflow.service';
// types
import { ICellRendererParams } from 'ag-grid-community';
import { IWorkflow } from 'ui/modules/Core/types/interfaces/workflow.interface';
// Shared constants and translation
import model_workflow from 'src/locales/en/model/workflow';
// constants
import { steps as WorkflowSteps } from 'ui/modules/Core/constants/workflow/workflow.constant';

const baseApiUrl = `${server}${basePath}/`;
const serverUrl = `${server}${getApiPrefix()}/`;

export default {
	referenceData: false,
	icon: (
		<span className="fa-layers fa-fw">
			<FontAwesomeIcon icon={faSquare} transform="right-3" />
			<FontAwesomeIcon icon={faEllipsisHAlt} transform="shrink-1 left-3" />
		</span>
	),
	url: `${serverUrl}${data}/workflows`,
	oldUrl: `${baseApiUrl}workflow`,
	ingestWorkflowUrl: `${serverUrl}${ingestWorkflow}`,
	urlParams: 'relation=[dataset, scheduled_task, user]',
	path: '/workflows',
	title: 'model.workflow.types.ingest.name_plural',
	paramSearch: { hastobe: false, where: 'type eq ingest' },
	paramUrl: '/workflow',
	pageTitle: {
		list: 'Workflow',
		action: {
			create: 'New workflow',
			edit: 'Edit workflow',
		},
	},
	onReceiveNotification(event: any) {
		if (
			event.code === 'workflow_step_started' ||
			event.code === 'workflow_succeeded' ||
			event.code === 'file_loading_completed' ||
			event.code === 'workflow_failed' ||
			event.code === 'script_execution_failed' ||
			event.code === 'file_conversion_failed' ||
			event.code === 'invalid_format' ||
			event.code === 'invalid_file'
		) {
			let newStatus = 'running';

			if (event.code === 'workflow_succeeded' || event.code === 'file_loading_completed') {
				newStatus = 'success';
			}
			if (
				event.code === 'workflow_failed' ||
				event.code === 'script_execution_failed' ||
				event.code === 'file_conversion_failed' ||
				event.code === 'invalid_format' ||
				event.code === 'invalid_file'
			) {
				newStatus = 'error';
			}
			this._alterRowData(event.object_id, {
				last_status: newStatus,
				last_step: event.details,
			});
		}

		if (event.code !== 'workflow_step_ended' && event.type !== 'system') {
			pushNotification(
				this.props.addToast,
				this.props.t(`model.workflow.${event.type}.${event.code}`),
				event.type,
				(event.type === 'error' || event.code === 'script_notification') && event.details
					? JSON.stringify(event?.details)
					: ''
			);
		}
	},
	columns: [
		{
			headerName: 'State',
			field: 'state',
			type: 'stateWorkflow',
			typeParams: {
				icons: {
					runnable: <FontAwesomeIcon icon={faCircle} className=" mr-0 recording" transform="grow-1" />,
					standby: <FontAwesomeIcon icon={faPause} className="mr-0" transform="grow-1" />,
					stopped: <FontAwesomeIcon icon={faStop} className="mr-0" transform="grow-1" />,
				},
			},
			minWidth: 85,
			filter: true,
			values: ['standby', 'runnable', 'stopped'],
		},

		{
			headerName: 'Trigger',
			field: 'trigger_type',
			filterValueGetter: ({ data }: any) => {
				const trigger = data.trigger_type;
				if (!trigger || trigger === 'none') {
					return model_workflow.list.trigger.none;
				}
				return (model_workflow.list.trigger as any)[trigger];
			},
			cellRenderer(params: any) {
				if (!params.value || params.value === 'none') return model_workflow.list.trigger.none;
				return (model_workflow.list.trigger as any)[params.value];
			},
			minWidth: 110,
		},

		{
			headerName: 'Frequency',
			field: 'dataset.frequency',
			minWidth: 135,
		},
		{
			headerName: 'Source',
			field: 'dataset.source',
			type: 'reference',
			referenceType: 'source',
			minWidth: 180,
			width: 180,
		},
		{
			headerName: 'Dataset',
			field: 'dataset',
			type: 'reference',
			minWidth: 220,
			width: 220,
		},
		{
			headerName: 'Dataset code',
			field: 'dataset.code',
			minWidth: 220,
			width: 220,
		},
		{
			headerName: 'Execution path',
			field: 'last_step',
			type: 'executionPath',
			filter: 'agSetColumnFilter',
			filterParams: {
				values: WorkflowSteps.map((step) => step.id),
				buttons: ['reset'],
				applyMiniFilterWhileTyping: true,
				cellRenderer: 'filterExecutionPathRenderer',
				suppressSorting: true,
			},
			filterValueGetter: (params: ICellRendererParams) => {
				return params?.data?.last_step || null;
			},
			minWidth: 190,
		},
		{
			headerName: 'Loader',
			field: 'loader_type',
			filterValueGetter: ({ data }: ICellRendererParams) => {
				const loader = data.loader_type;
				if (!loader || loader === 'none') {
					return model_workflow.list.loader.none;
				}
				return (model_workflow.list.loader as any)[loader];
			},
			cellRenderer(params: ICellRendererParams) {
				if (!params.value || params.value === 'none') return model_workflow.list.loader.none;
				return (model_workflow.list.loader as any)[params.value];
			},
			minWidth: 135,
		},
		{
			headerName: 'Status',
			field: 'last_status',
			type: 'tag',
			typeParams: {
				null_value: 'Not yet run',
			},
			minWidth: 90,
			values: ['success', 'error', 'under_edit', 'ready', 'running'],
		},
		{
			headerName: 'Category',
			field: 'dataset.category',
			minWidth: 160,
			width: 160,
			type: 'reference',
			referenceType: 'category',
		},
		{
			headerName: 'Last data extraction trigger',
			field: 'last_run_at',
			type: 'datetime',
			minWidth: 200,
			description: 'Last execution date of the workflow',
		},
		{
			headerName: 'Last data update',
			field: 'dataset.last_data_change_date',
			type: 'datetime',
			description: 'Last date at which the number of rows in the dataset has changed',
			minWidth: 200,
		},
		{
			headerName: 'Last execution - Incoming data',
			field: 'dataset.last_loaded_data_date',
			type: 'datetime',
			description: 'Last date of data loading in the workflow',
			minWidth: 220,
		},
		{
			headerName: 'Next data extraction trigger',
			field: 'scheduled_tasks',
			type: 'datetime',
			description: 'Next scheduled date for data fetching process execution',
			valueGetter(params: { data: IWorkflow }) {
				if (!params.data) return null;
				if (params.data.scheduled_tasks && params.data.scheduled_tasks.length > 0) {
					const data: any = new min(params.data.scheduled_tasks, 'next_run_planned_at');
					return {
						value: data.next_run_planned_at,
						timezone: data.timezone,
					};
				}
				return null;
			},
			minWidth: 200,
		},
		{
			headerName: 'Labels',
			field: 'dataset.labels',
			type: 'labels',
			minWidth: 200,
		},
		{
			headerName: 'Data steward',
			field: 'dataset.data_steward',
			type: 'reference',
			minWidth: 150,
			referenceType: 'user',
		},
		{
			headerName: 'Created by',
			minWidth: 150,
			field: 'created_by_user',
			type: 'reference',
			referenceType: 'user',
		},
	],
	canCreate: false,
	canEdit: true,
	canDelete: false,
	canExecuteWorkflow: true,
	showCheckBox: true,
	canChangeState: true,
	canChangeTags: true,
	loadLastUpdate: true,
	getService: () => ({
		instance: getWorkflowNewService,
	}),
};
