// disable no unused state because we are using it in the context
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { v4 as uuid } from 'uuid';
import { filter } from 'lodash';
import { getMyPermissions } from 'ui/modules/Core/services/user.service';

import NotificationContext from './NotificationContext';

export default class NotificationProvider extends Component {
	connection = null;

	connectRetries = 0;

	subscriptions = {};

	subscriptionsType = {};

	pingInterval = null;

	retryTimeout = null;

	constructor(props) {
		super(props);
		this.state = {
			events: [],
			notifications: [],
			notificationReady: false,
			pushNotification: this.pushNotification,
			popNotification: this.popNotification,
			replaceNotification: this.replaceNotification,
			subscribeToObject: this.subscribeToObject,
			unsubscribeFromObject: this.unsubscribeFromObject,
			subscribeToType: this.subscribeToType,
			unsubscribeFromType: this.unsubscribeFromType,
		};
	}

	componentDidMount() {
		this.connect();
	}

	handleOpen() {
		if (this.pingInterval) clearInterval(this.pingInterval);
		this.connectRetries = 0;

		// Sends a ping on regular intervals
		this.pingInterval = setInterval(() => {
			try {
				this.connection.send('ping');
			} catch (e) {
				console.warn('ping error', e);
			}
		}, 300000); // 5 minutes
	}

	handleMessage(event) {
		let payload = null;
		try {
			payload = JSON.parse(event.data);
		} catch (e) {
			console.error('Error parsing message', event.data);
			return;
		}

		const subscriptions = this.subscriptions[payload.object_type];
		const subscriptionsType = this.subscriptionsType[payload.object_type];

		if (subscriptionsType) {
			for (let i = 0; i < subscriptionsType.length; i++) {
				subscriptionsType[i].callback(payload);
			}
		}
		if (subscriptions && payload.object_id && subscriptions[payload.object_id]) {
			for (let i = 0; i < subscriptions[payload.object_id].length; i++) {
				subscriptions[payload.object_id][i].callback(payload);
			}
		}
	}

	handleClose() {
		this.connection = null;
		if (this.pingInterval) {
			clearInterval(this.pingInterval);
		}
		setTimeout(() => {
			this.connectWithRetries();
		}, 10);
	}

	pushNotification = (component) => {
		this.setState({
			notifications: [...this.state.notifications, component],
		});
	};

	popNotification = (number = 1) => {
		this.setState({
			notifications: [...this.state.notifications.slice(0, this.state.notifications.length - number)],
		});
	};

	replaceNotification = (component) => {
		this.setState({
			notifications: [...this.state.notifications.slice(0, this.state.notifications.length - 1), component],
		});
	};

	subscribeToType = (objectType, callback) => {
		if (!this.subscriptionsType[objectType]) this.subscriptionsType[objectType] = [];

		const key = uuid();
		this.subscriptionsType[objectType].push({ id: key, callback });

		return key;
	};

	subscribeToObject = (objectType, objectId, callback) => {
		if (!this.subscriptions[objectType]) this.subscriptions[objectType] = {};
		if (!this.subscriptions[objectType][objectId]) this.subscriptions[objectType][objectId] = [];

		const key = uuid();

		if (this.subscriptions[objectType][objectId].length === 0) {
			this.subscriptions[objectType][objectId].push({ id: key, callback });
		} else {
			return this.subscriptions[objectType][objectId].id;
		}

		return key;
	};

	unsubscribeFromObject = (objectType, objectId, key) => {
		if (!this.subscriptions[objectType]) this.subscriptions[objectType] = {};
		if (!this.subscriptions[objectType][objectId]) this.subscriptions[objectType][objectId] = [];

		this.subscriptions[objectType][objectId] = filter(
			this.subscriptions[objectType][objectId],
			(v) => v.id !== key
		);
	};

	unsubscribeFromType = (objectType, key) => {
		if (!this.subscriptionsType[objectType]) this.subscriptionsType[objectType] = [];

		this.subscriptionsType[objectType] = filter(this.subscriptionsType[objectType], (v) => v.id !== key);
	};

	connectWithRetries() {
		if (this.retryTimeout) {
			// retry already scheduled, skip
			return;
		}
		// increment retries only if online, otherwise it will keep trying to connect
		if (!window.navigator.onLine) {
			this.connectRetries = 0;
		}
		this.connectRetries++;
		if (this.connectRetries > 3) {
			// check if the user is still logged in, otherwise continue with the retries
			// if the user is not logged in, getMyPermissions will trigger the logout
			getMyPermissions().catch(() => {});
		}

		// retry connection with exponential backoff
		this.retryTimeout = setTimeout(
			() => {
				this.retryTimeout = null;
				this.connect();
			},
			2 ** this.connectRetries * 3000
		);
	}

	connect() {
		const token = JSON.parse(localStorage.getItem('token'))?.jwtToken;

		if (typeof token !== 'undefined' && !this.connection) {
			this.connection = new WebSocket(`wss://bgj82rj8di.execute-api.eu-west-1.amazonaws.com/dev?token=${token}`);
			this.connection.onopen = this.handleOpen.bind(this);
			this.connection.onmessage = this.handleMessage.bind(this);
			this.connection.onclose = this.handleClose.bind(this);
			this.connection.onerror = this.handleClose.bind(this);
		}
	}

	render() {
		return <NotificationContext.Provider value={this.state}>{this.props.children}</NotificationContext.Provider>;
	}
}
