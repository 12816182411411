import DataService from 'utils/DataService';
import { uniqBy } from 'lodash';

import { fundamentals, getApiPrefix, server } from 'src/utils/api-prefix.helper';

import config from 'config';

export const getProductionService = () => {
	return new DataService({
		url: `${server}${getApiPrefix()}/${fundamentals}/production`,
		urlParams: '',
		token: true,
	});
};

export const getOneProduction = (code: string) => {
	return new DataService({
		url: `${server}${getApiPrefix()}/${fundamentals}/production/`,
		urlParams: '',
		token: true,
	}).getClean(code);
};

export const updateProduction = async (id: string, values: any) => {
	try {
		const result = await new DataService({
			url: `${server}${getApiPrefix()}/${fundamentals}/production`,
			urlParams: '',
		}).update(id, values);

		return result.data;
	} catch (err) {
		return err;
	}
};

export const createProduction = async (values: any) => {
	try {
		const result = await new DataService({
			url: `${server}${getApiPrefix()}/${fundamentals}/production/create`,
			urlParams: '',
		}).create(values);

		return result.data;
	} catch (err) {
		return err;
	}
};

// TODO: To be remove after removing old sharing
export const getAvailableProductions = async (id: string, selectFormat = false) => {
	const tfs = await Promise.all([
		getProductionService().getAll(),
		new DataService({
			url: `${config.api.baseUrl}data_sharing`,
			token: true,
			urlParams: '?relation=[production]',
		}).getAll(),
	]);
	const data: string[] = [];
	tfs.map((x: any, i: number) => {
		if (i === 1) data.push(x.data.map((c: any) => c.trade_flow));
		else data.push(x.data.result.filter((c: any) => c.id !== id));
	});

	if (selectFormat) {
		return uniqBy(data.flat(), 'code').map((el: any) => ({
			id: el.id,
			key: el.id,
			title: el.name,
			description: el.name,
			code: el.code,
			parent: el.parent ? el.parent.name : null,
		}));
	}

	return uniqBy(data.flat(), 'code');
};
