// config
import userConfig from 'ui/modules/Core/config/user.config';
// helpers
import DataService from 'src/utils/DataService';
import { getApiPrefix, server } from 'src/utils/api-prefix.helper';
// constants
import { twitterAuthUrl } from 'ui/modules/Core/constants/user.constant';

/**
 * get users list
 * @returns
 */
export function getUserService() {
	return new DataService({
		url: `${userConfig.url}/`,
		urlParams: '',
		token: true,
	});
}

/**
 * post users with connectors
 * @returns
 */
export function getUsersWithConnectors(condition: string | null) {
	return new DataService({
		url: `${userConfig.url}/user_connectors`,
		urlParams: '',
		token: true,
	}).post(condition);
}

/**
 * get user details
 * @param id
 * @returns
 */
export const getUser = async (id: string) => {
	try {
		const result = await new DataService({
			url: userConfig.url,
			urlParams: '',
		}).getClean(id);
		return result.data.result;
	} catch (err) {
		return null;
	}
};

/**
 * create user
 * @param payload
 * @returns
 */
export const createUser = async (payload: any) => {
	return await new DataService({
		url: `${userConfig.url}/`, //TODO: remove '/'
		urlParams: '',
	}).create(payload);
};

/**
 * update user
 * @param id
 * @param payload
 * @returns
 */
export const updateUser = async (id: string, payload: any) => {
	return await new DataService({
		url: userConfig.url,
		urlParams: '',
	}).update(id, payload);
};

/**
 * new delete user with migation to other user
 * @param id
 * @returns
 */
export const migrateAndDeleteUser = async (id: string, userToMigrate: string | null) => {
	return await new DataService({
		url: `${userConfig.url}/delete`,
		urlParams: '',
	}).post({
		from_user_id: id,
		to_user_id: userToMigrate,
	});
};

/**
 * get user connector
 * @param id
 * @returns
 */
export const getUserConnectors = async (id: string) => {
	try {
		const result = await new DataService({
			url: userConfig.url,
			urlParams: '',
		}).getClean(id + '/connectors');
		return result.data.result;
	} catch (err) {
		return null;
	}
};

// get connector Url
export const getConnectorUrl = async (values: string, callback: () => void, type: string) => {
	try {
		const dataService = await new DataService({
			urlParams: values,
		}).getClean(`${server}${getApiPrefix()}/users/${type}/auth-url`);

		if (dataService.data) {
			callback(dataService.data);
			return dataService.data;
		}
		throw new Error('Error getting connector url');
	} catch (err: unknown) {
		if (err instanceof Error) {
			return err.message;
		}
		return 'An unexpected error occurred';
	}
};

/**
 * get Twitter Url
 * @param values
 * @param callback
 * @returns
 */
export const getTwitterUrl = async (values: any, callback: any) => {
	try {
		const dataService = await new DataService({
			urlParams: [],
		})
			.getRequestService()
			.get(twitterAuthUrl);

		if (dataService.data) {
			callback(dataService.data);
			return dataService.data;
		}
		throw new Error('Error getting twitter url');
	} catch (err: any) {
		return err.message;
	}
};

/**
 * Get all user Permissions
 * @returns
 */
export function getPermissionsService() {
	return new DataService({
		url: userConfig.api.permissions,
		urlParams: '',
		token: true,
	}).getAll();
}

/**
 * Get Permissions of user connected
 * @returns
 */
export function getMyPermissions() {
	return new DataService({
		url: userConfig.api.myPermissions,
		urlParams: '',
		token: true,
	}).getAll();
}
