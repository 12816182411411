import {
	faAbacus,
	faEnvelope,
	faGlobe,
	faAlbumCollection,
	faPlug,
	faProjectDiagram,
} from '@fortawesome/pro-light-svg-icons';
import { basePath, server } from 'src/utils/api-prefix.helper';
// configs
import configUser from 'src/ui/modules/Core/config/user.config';
import configLabel from 'src/ui/modules/Core/config/label.config';
import configVoyage from 'ui/modules/Freight/config/voyage.config';
import configDataset from 'src/ui/modules/Core/config/dataset.config';
import configTradeFlow from 'ui/modules/Fundamentals/config/tradeflow.config';
import configForecast from 'ui/modules/Fundamentals/config/forecast.config';
import configProduction from 'ui/modules/Fundamentals/config/production.config';
import configWorkflow from 'src/ui/modules/Core/config/workflow.config';
import configCategory from 'src/ui/modules/Core/config/category.config';
import configFreight from 'src/ui/modules/Freight/config/freight.config';
import configDashboard from 'src/ui/modules/Dashboards/config/dashboard.config';
import configEmail from 'src/ui/modules/Storage/config/email.config';
import configEmailRuleset from 'src/ui/modules/Storage/config/emailRuleset.config';
import configReferenceData from 'src/ui/modules/References/config/reference-data';
import configCompany from 'src/ui/modules/References/config/company.config';
import configCompanyGroup from 'src/ui/modules/References/config/company-group.config';
import configCompanyAndGroup from 'ui/modules/References/config/company-and-group.config';
import configCostProductionItem from 'src/ui/modules/References/config/cost-production-item.config';
import configCurrency from 'src/ui/modules/References/config/currency.config';
import configCustomCode from 'src/ui/modules/References/config/custom-code.config';
import configCustomCodeNotLinked from 'src/ui/modules/References/config/custom-code-not-linked.config';
import configDataType from 'src/ui/modules/References/config/data-type.config';
import configDeliveryTerm from 'src/ui/modules/References/config/delivery-term.config';
import configExchangeMonthReference from 'src/ui/modules/References/config/exchange-month-reference.config';
import configExchange from 'src/ui/modules/References/config/exchange.config';
import configLocation from 'src/ui/modules/References/config/location.config';
import configLocationGroup from 'src/ui/modules/References/config/location-group.config';
import configLocationAndGroup from 'src/ui/modules/References/config/location-and-group.config';
import configPeriodAggregationType from 'src/ui/modules/References/config/period-aggregation-type.config';
import configPriceType from 'src/ui/modules/References/config/price-type.config';
import configProductCategory from 'src/ui/modules/References/config/product-category.config';
import configProduct from 'src/ui/modules/References/config/product.config';
import configProductGroup from 'src/ui/modules/References/config/product-group.config';
import configProductAndGroup from 'src/ui/modules/References/config/product-and-group.config';
import configQuoteType from 'src/ui/modules/References/config/quote-type.config';
import configSndItem from 'src/ui/modules/References/config/snd-item.config';
import configSource from 'src/ui/modules/References/config/source.config';
import configTradeFlowMode from 'src/ui/modules/References/config/trade-flow-mode.config';
import configTransportCategory from 'src/ui/modules/References/config/transport-category.config';
import configTransportType from 'src/ui/modules/References/config/transport-type.config';
import configUnit from 'src/ui/modules/References/config/unit.config';
import configVegetationIndexVariable from 'src/ui/modules/References/config/vegetation-index-variable.config';
import configVesselStatus from 'src/ui/modules/References/config/vessel-status.config';
import configSystemEntity from 'ui/modules/Core/config/system-entity.config';
import configReport from 'ui/modules/Reporting/config/report.config';
import configPipeline from 'ui/modules/Pipeline/configs/pipeline.config';
import configFile from 'src/ui/modules/Storage/config/file.config';
// constants
const baseApiUrl = `${server}${basePath}/`;

export default {
	dashboard: configDashboard,
	forecast: configForecast,
	production: configProduction,
	trade_flow: configTradeFlow,
	pipeline: configPipeline,
	dataset: configDataset,
	workflow: configWorkflow,
	transform_workflow: {
		referenceData: false,
		iconName: faProjectDiagram,
		url: `${baseApiUrl}workflow`,
		urlParams: 'deep=true',
		path: '/transform-workflows',
		pageTitle: {
			list: 'Transform workflow',
			action: {
				create: 'New transform workflow',
				edit: 'Edit transform workflow',
			},
		},
		title: 'model.workflow.types.transform.name_plural',
		paramSearch: { hastobe: false, where: 'type ne ingest' },
		paramUrl: '/workflow',
		useSearchUrl: true,
		columns: [
			{
				headerName: 'Name',
				field: 'name',
				minWidth: 220,
				width: 400,
			},
			{
				headerName: 'Status',
				field: 'last_status',
				type: 'tag',
				typeParams: {
					mapping: {
						success: 'green',
						error: 'red',
						ready: 'purple',
					},
					null_value: 'Not yet run',
				},
				minWidth: 90,
				values: ['success', 'error', 'under_edit', 'ready', 'running'],
			},
			{
				headerName: 'Updated at',
				field: 'updatedAt',
				type: 'datetime',
				minWidth: 200,
			},
		],
		onReceiveNotification(event) {
			if (
				event.code === 'workflow_step_started' ||
				event.code === 'workflow_succeeded' ||
				event.code === 'workflow_failed'
			) {
				let newStatus = 'running';

				if (event.code === 'workflow_succeeded') {
					newStatus = 'success';
				}
				if (event.code === 'workflow_failed') {
					newStatus = 'error';
				}

				this._alterRowData(
					event.object_id,
					{
						last_status: newStatus,
						last_step: event.details,
					},
					newStatus === 'success' || newStatus === 'error'
				);
			}

			if (event.code !== 'workflow_step_ended' && event.type !== 'system') {
				this.pushNotification(
					this.props.t(`model.workflow.${event.type}.${event.code}`),
					event.type,
					event.type === 'error' || event.code === 'script_notification' ? event.details : null
				);
			}
		},
		canView: false,
		canCreate: true,
		canEdit: true,
		canDelete: true,
		canExecuteWorkflow: true,
	},
	user: configUser,
	email: configEmail,
	email_ruleset: configEmailRuleset,
	files: configFile,
	label: configLabel,
	category: configCategory,
	reference_data: configReferenceData,
	company: configCompany,
	company_group: configCompanyGroup,
	cost_production_item: configCostProductionItem,
	currency: configCurrency,
	custom_code: configCustomCode,
	data_type: configDataType,
	delivery_term: configDeliveryTerm,
	exchange_month_reference: configExchangeMonthReference,
	exchange: configExchange,
	location_group: configLocationGroup,
	location: configLocation,
	period_aggregation_type: configPeriodAggregationType,
	price_type: configPriceType,
	product_category: configProductCategory,
	product_group: configProductGroup,
	product: configProduct,
	quote_type: configQuoteType,
	snd_item: configSndItem,
	source: configSource,
	trade_flow_mode: configTradeFlowMode,
	transport_category: configTransportCategory,
	transport_type: configTransportType,
	unit: configUnit,
	vegetation_index_variable: configVegetationIndexVariable,
	vessel_status: configVesselStatus,
	location_and_group: configLocationAndGroup,
	company_and_group: configCompanyAndGroup,
	product_and_group: configProductAndGroup,
	custom_code_not_linked: configCustomCodeNotLinked,
	dataset_global_mapping: {
		referenceData: false,
		iconName: faGlobe,
		url: `${baseApiUrl}dataset-global-mapping`,
		urlParams: '',
		path: '/global-value-mapping',
		pageTitle: {
			list: 'Global value mapping',
			action: {
				create: '',
				edit: '',
			},
		},
	},
	dataset_field_mapping: {
		referenceData: false,
		iconName: faAlbumCollection,
		url: `${baseApiUrl}dataset-field-mapping`,
		urlParams: '',
		path: '/dataset-field-mapping',
		pageTitle: {
			list: 'Dataset field mapping',
			action: {
				create: '',
				edit: '',
			},
		},
	},
	twitter_tab: {
		referenceData: false,
		iconName: faEnvelope,
		url: `${baseApiUrl}twitter_tab`,
		recordid: 'id',
		paramUrl: '/twitter_tab',
		path: '/twitter/news',
		paramSearch: { hastobe: false, where: 'workflow_id is null' },
		urlParams: 'relation=[user]&limit=1000',
		useSearchUrl: true,
		pageTitle: {
			list: 'Twitter Tabs',
			action: {
				create: 'Add Twitter tab',
				edit: 'Edit Twitter tab',
			},
		},
		columns: [
			{
				headerName: 'Updated',
				field: 'updatedAt',
				type: 'datetime',
				minWidth: 200,
			},
			{
				headerName: 'Handle',
				field: 'name',
				minWidth: 220,
				width: 400,
			},
			{
				headerName: 'Created by',
				field: 'createdBy',
				minWidth: 200,
			},
			{
				headerName: 'Created',
				field: 'createdAt',
				type: 'datetime',
				minWidth: 200,
			},
		],

		duplicateRelation: '?relation=[email_ruleset_rule]',
		canView: true,
		canCreate: true,
		canEdit: true,
		canDelete: true,
		canbeDuplicate: true,
		useTab: 'general',
	},
	twitter_account: {
		referenceData: false,
		iconName: faAbacus,

		url: `${baseApiUrl}twitter_account`,
		urlParams: 'relation=[organization]',
		path: '/twitter/accounts',
		pageTitle: {
			list: 'model.twitter_account.list', // "Twitter account",
			help: 'model.twitter_account.accounts_to_follow',
			action: {
				create: 'model.twitter_account.actions.create_new', // "New Twitter Account",
				edit: 'model.twitter_account.actions.edit',
			},
		},

		columns: [
			{
				headerName: 'Name',
				field: 'handle',
			},
			{
				headerName: 'Created by',
				minWidth: 150,
				field: 'user',
				type: 'reference',
				referenceType: 'user',
			},
			{
				headerName: 'Created',
				field: 'createdAt',
				type: 'datetime',
			},
			{
				headerName: 'Updated',
				field: 'updatedAt',
				type: 'datetime',
			},
		],

		duplicateRelation: '?relation=[organization]',
		canView: false,
		canCreate: true,
		canEdit: false,
		canDelete: true,
		canbeDuplicate: false,
		useTab: 'general',
	},
	tag: {
		referenceData: false,
		iconName: faAbacus,
		url: `${baseApiUrl}tag`,
		recordid: 'id', // required for duplication !
		urlParams: 'relation=[user,parent]',
		path: '/tags',
		parents: [],
		pageTitle: {
			list: 'model.tag.list', // "Tags"
			help: 'model.tag.tags_to_filter_with',
			action: {
				create: 'model.tag.actions.create_new', // "New Tag"
				edit: 'model.tag.actions.edit', // "Edit Tag"
			},
		},
		columns: [
			{
				headerName: 'Name',
				field: 'name',
				minWidth: 200,
			},
			{
				type: 'tag',
				tag_separator: ';',
				headerName: 'Keywords',
				field: 'keywords',
				minWidth: 200,
			},
			{
				headerName: 'Parent tag',
				field: 'parent.name',
				minWidth: 200,
			},
			{
				headerName: 'Updated At',
				field: 'updatedAt',
				type: 'datetime',
				minWidth: 200,
			},
			{
				headerName: 'Created At',
				field: 'createdAt',
				type: 'datetime',
				minWidth: 200,
			},
		],

		duplicateRelation: '?relation=[user]',
		canView: true,
		canCreate: true,
		canEdit: true,
		canDelete: true,
		useTab: 'general',
		parentField: 'parent',
	},
	lineup_consolidation: {
		referenceData: false,
		iconName: faAbacus,
		url: `${baseApiUrl}lineup_consolidation`,
		recordid: 'id', // required for duplication !
		urlParams: 'relation=[]',
		path: '/vessel-tracking/d-lineup',
		parentPage: {
			name: 'Vessel-tracking',
		},
		pageTitle: {
			list: 'Dlineups',
			action: {
				create: 'New Dlineups',
				edit: 'Edit Dlineups',
			},
		},
		columns: [
			{
				headerName: 'Name',
				field: 'name',
				minWidth: 220,
				width: 400,
			},
			{
				headerName: 'Code',
				field: 'code',
				minWidth: 220,
				width: 400,
			},
			{
				headerName: 'Product',
				field: 'product.name',
				minWidth: 200,
			},
			{
				headerName: 'Updated At',
				field: 'updatedAt',
				type: 'datetime',
				minWidth: 200,
			},
			{
				headerName: 'Created At',
				field: 'createdAt',
				type: 'datetime',
				minWidth: 200,
			},
		],

		duplicateRelation: '?relation=[user]',
		canView: true,
		canCreate: true,
		canEdit: true,
		canDelete: true,
		canBulkExecute: true,
		bulk_execution_model: 'lineup_consolidation',
		useTab: 'general',
		canExecuteLineupconsolidation: true,
	},
	freight: configFreight,
	voyage: configVoyage,
	system_entity: configSystemEntity,
	report: configReport,
	api_key: {
		referenceData: false,
		iconName: faPlug,
		url: `${baseApiUrl}api-key`,
		path: '/api_keys',
		columns: [
			{
				headerName: 'Name',
				field: 'name',
				hidden: false,
				order: 1,
			},
			{
				headerName: 'Created by',
				minWidth: 150,
				field: 'user',
				type: 'reference',
				referenceType: 'user',
			},
			{
				headerName: 'Created',
				field: 'createdAt',
				type: 'datetime',
			},
		],
		pageTitle: {
			list: 'API keys',
			action: {
				create: 'New api key',
				edit: 'Edit api key',
			},
		},
		canCreate: false,
		canEdit: false,
		canDelete: false,
	},
};
