import { getApiPrefix, basePath, dev, server, beta, data, referenceData, storage } from 'src/utils/api-prefix.helper';
import configRecords from './configRecords';
import configInterface from './interface.config';

// constants
const serverUrl = `${server}${getApiPrefix()}/`;
const isDnexr = getApiPrefix() === beta;

export default {
	s3: {
		REGION: 'eu-west-1',
		BUCKET: 'dn-d-dnext-s3',
	},
	apiGateway: {
		REGION: 'eu-west-1',
		URL: `${serverUrl}storage/request-upload-url`, // not used
		URLClient: `${serverUrl}storage/files/request-upload-url`,
		REST_URL: server + basePath,
	},
	api: {
		split: 3000,
		SERVER: server,
		base: server,
		baseUrl: `${server}${basePath}/`,
		endpoint: `${server}${getApiPrefix()}`,
		elasticSearchUrl: `${server}index/private`,
		duplicateUrl: `${server}${basePath}/duplicate`,
		serviceUrl: `${server}services`,
		clientUrl: `${server}client`,
		catalogUrl: `${server}index`,
		searchUrl: `${server}search`,
		tradeflowUrl: `${server}fundamentals/tradeflow`,
		fundamentals: `${server}fundamentals`,
		ingestWorkflowUrl: `${server}ingest-workflow`,
		sharing: `${server + getApiPrefix()}/sharing/`,
		dataManagement: `${server}${getApiPrefix()}/data-management/`,
		dataPrep: `${server}${getApiPrefix()}/data-prep`,
		referenceDataUrl: `${server}${getApiPrefix()}/${referenceData}/`,
		dataUrl: `${server}${getApiPrefix()}/${data}`,
		taskUrl: `${server}${getApiPrefix()}/tasks`,
		emailUrl: `${server}${getApiPrefix()}/${storage}/email`,
		symbol: {
			url: `${server}${basePath}/symbol`,
		},
		dataset: {
			url: `${server}${basePath}/dataset`,
		},

		dashboard: {
			url: `${server}${basePath}/dashboard`,
		},
		user: {
			url: `${server}${getApiPrefix()}/users`,
		},
		group: {
			url: `${server}${basePath}/group`,
		},
		access_right: {
			url: `${server}${basePath}/access_right`,
		},
		dataset_field: {
			url: `${server}${basePath}/dataset_field`,
		},
		dataset_global_mapping: {
			url: `${server}${basePath}/dataset_global_mapping`,
		},
		dataset_field_mapping: {
			url: `${server}${basePath}/dataset_field_mapping`,
		},
		source: {
			url: `${server}${basePath}/source`,
		},
		source_alert: {
			url: `${server}${basePath}/source_alert`,
		},
		unit: {
			url: `${server}${basePath}/unit`,
		},
		customCode: {
			url: `${server}${basePath}/custom-code`,
		},
		custom_code: {
			url: `${server}${basePath}/custom-code`,
		},
		exchange: {
			url: `${server}${basePath}/exchange`,
		},
		location: {
			url: `${server}${basePath}/location`,
		},
		location_group: {
			url: `${server}${basePath}/location_group`,
		},
		price_type: {
			url: `${server}${basePath}/price-type`,
		},
		snd_item: {
			url: `${server}${basePath}/snd-item`,
		},
		period_aggregation_type: {
			url: `${server}${basePath}/period_aggregation_type`,
		},
		company: {
			url: `${server}${basePath}/company`,
		},
		quote_type: {
			url: `${server}${basePath}/quote-type`,
		},
		data_type: {
			url: `${server}${basePath}/data-type`,
		},
		exchange_month_reference: {
			url: `${server}${basePath}/exchange-month-reference`,
		},
		vegetation_index_variable: {
			url: `${server}${basePath}/vegetation-index-variable`,
		},
		cost_production_item: {
			url: `${server}${basePath}/cost-production-item`,
		},
		delivery_term: {
			url: `${server}${basePath}/delivery-term`,
		},
		transport_category: {
			url: `${server}${basePath}/transport-category`,
		},
		vessel_status: {
			url: `${server}${basePath}/vessel-status`,
		},
		product: {
			url: `${server}${basePath}/product`,
		},
		product_category: {
			url: `${server}${basePath}/product-category`,
		},
		currency: {
			url: `${server}${basePath}/currency`,
		},
		workflow: {
			url: `${server}${basePath}/workflow`,
		},
		location_alias: {
			url: `${server}${basePath}/location_alias`,
		},
		product_alias: {
			url: `${server}${basePath}/product_alias`,
		},
		transport_type: {
			url: `${server}${basePath}/transport_type`,
		},
		trade_flow_mode: {
			url: `${server}${basePath}/trade_flow_mode`,
		},
		trade_flow: {
			url: `${server}${basePath}/trade_flow`,
		},
		location_and_group: {
			url: `${server}${basePath}/location_and_group`,
		},
		company_and_group: {
			url: `${server}${basePath}/company_and_group`,
		},
		product_and_group: {
			url: `${server}${basePath}/product_and_group`,
		},
		company_group: {
			url: `${server}${basePath}/company_group`,
		},
		product_group: {
			url: `${server}${basePath}/product_group`,
		},
		transport_workflow: {
			url: `${server}${basePath}/transport_workflow`,
		},
		data_sharing: {
			url: `${server}${basePath}/data_sharing`,
		},
		script: {
			url: `${server}${basePath}/script`,
		},
		tag: {
			url: `${server}${basePath}/tag`,
		},
	},
	modules: {
		crud: `${server}${basePath}`,
		api: `${server}${dev}`,
	},
	interface: configInterface,
	convertReferenceString: {
		Dataset: 'dataset',
		User: 'user',
		Group: 'group',
		Product: 'product',
		Email: 'email',
		Location: 'location',
		Currency: 'currency',
		Source: 'source',
		DataSource: 'source',
		Unit: 'unit',
		Exchange: 'exchange',
		Category: 'category',
		Company: 'company',
		Workflow: 'workflow',
		Dashboard: 'dashboard',
		CompanyGroup: 'company_group',
		DataType: 'data_type',
		DeliveryTerm: 'delivery_term',
		CostProductionItem: 'cost_production_item',
		ExchangeMonthReference: 'exchange_month_reference',
		VegetationIndexVariable: 'vegetation_index_variable',
		LocationGroup: 'location_group',
		ProductGroup: 'product_group',
		QuoteType: 'quote_type',
		SndItem: 'snd_item',
		TradeFlowMode: 'trade_flow_mode',
		ProductCategory: 'product_category',
		PriceType: 'price_type',
		PeriodAggregationType: 'period_aggregation_type',
		TransportType: 'transport_type',
		TransportCategory: 'transport_category',
		CustomCode: 'custom_code',
		VesselStatus: 'vessel_status',
		TransformWorkflow: 'transform_workflow',
		LocationAndGroup: 'location_and_group',
		CompanyAndGroup: 'company_and_group',
		productAndGroup: 'product_and_group',
		Tag: 'tag',
	},
	records: configRecords,
	systemUser: 'ec044f9e-c52d-4291-99aa-0e7381bfea50',
	labels: {
		system: {
			'dev': 'cyan',
			'test': 'lime',
			'prod': 'green',
			'to review': 'gold',
			'to fix': 'red',
			'low priority': 'blue',
			'high priority': 'volcano',
			'delivered': 'magenta',
		},
		dataset: {
			'to monitor': 'orange',
			'obsolete': 'lightgrey',
			'trade flow': 'purple',
			'D-lineup': 'blue',
			'partial data': 'yellow',
			'final data': 'violet',
			'dashboard': 'default',
			...(isDnexr ? { Graderco: 'geekblue' } : {}),
		},
		trade_flow: {
			'platform only': 'pink',
		},
		data_prep: {
			success: 'green',
			error: 'red',
			ready: 'purple',
			runned: 'blue',
		},
	},
	form: {
		formLayout: 'vertical',
		fullSpan: 18,
		halfSpan: 9,
		formItemLayout: {
			labelCol: { xs: { span: 12 }, sm: { span: 12 }, md: { span: 12 }, lg: { span: 12 } },
			wrapperCol: {
				xs: { span: 12 },
				sm: { span: 12 },
				md: { span: 12 },
				lg: { span: 12 },
			},
		},
		verticalItemLayout: {
			labelCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 6 } },
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 24 },
				md: { span: 14 },
			},
		},
		horizontalItemLayout: {
			labelCol: {
				xs: { span: 12 },
				sm: { span: 12 },
				md: { span: 12 },
				lg: { span: 12 },
			},
			wrapperCol: {
				xs: { span: 12 },
				sm: { span: 12 },
				md: { span: 12 },
				lg: { span: 12 },
			},
		},
	},
	restrictedPermission: {
		read: ['voyage', 'transform_workflow'],
		create: ['workflow', 'dashboard', 'voyage'],
		delete: ['dataset', 'voyage'],
	},
};
