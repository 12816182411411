export default {
	global: {
		name: 'Global',
		data_management: 'Data management',
		reference_management: 'Reference management',
	},
	organization: {
		title: 'Organizations',
		subtitle: "Manage your organization's settings.",
		description: "Manage your organization's settings.",
		actions: {
			references: 'References',
			contracts: 'Contracts',
			resource_group: 'Resource groups',
		},
		dashboard: {
			options: {
				dashboard_datasets: 'List dashboards and datasets not link to a resource group',
				resource_groups: 'List resource groups not shared with a client but should have been',
				datasets_shared_not_tagged: 'List datasets shared that are not tagged as final',
				dataset_not_shared: 'List datasets not shared link to shared dashbords ',
			},
		},
	},
	contracts: {
		title: 'Contracts',
		subtitle: "Manage your organization's contracts.",
		description: "Manage your organization's contracts.",
		actions: {
			add: 'Add a contract',
			edit: 'Edit a contract',
			delete: 'Delete a contract',
		},
		fields: {
			name: 'Name',
			description: 'Description',
			reference: 'Reference',
			contract: 'Contract',
			contract_plural: 'Contracts',
			contract_type: 'Contract type',
		},
	},
	resource_group: {
		title: 'Resource group',
		subtitle: "Manage your organization's resource groups.",
		description: "Manage your organization's resource groups.",
		actions: {
			add: 'Add a resource group',
			edit: 'Edit a resource group',
			delete: 'Delete a resource group',
		},
		fields: {
			name: 'Name',
			description: 'Description',
			resource_group: 'Resource group',
			resource_group_plural: 'Resource groups',
		},
	},
	reference_management: {
		'title': 'Reference management',
		'mapping': 'Last value mapping',
		'global': 'Last global value mapping',
		'last-references': 'Last references created',
		'description':
			'Manage your references. A reference is a list of values that can be used to enrich your data. For example, you can create a reference of location and use it to enrich the country field of your data.',
		'infos': {
			reference: 'Reference',
			reference_plural: 'References',
			reference_count: 'You have {count} reference',
			ruleset: 'Ruleset for references',
			ruleset_saved: 'Ruleset saved',
			correction: 'Correction',
		},
		'success': {
			references_approved: 'References approved',
		},
		'errors': {
			references_approved: 'You don’t have the right to approve references',
		},
		'to-be-approved-references': 'To be approved references',
	},
	correction: {
		title: 'Correction',
		tabs: {
			invalids: 'Invalids',
			invliad_description: 'The entries below do not match the rules established for the naming conditions',
			duplicates: 'Duplicates',
			duplicates_description: 'The entries below are duplicates, you may want to merge them.',
			merge: 'Merge',
			merge_description:
				'In this screen, you can redefine the mapped references. For example, you can change a reference currently wrongly mapped to Mexico (city) to Mexico (country). ',
		},
		fields: {
			reference: 'Reference',
			related_dataset: 'dataset that use this reference',
			select_entries: 'Please select all the datasets and columns where you would like to apply the replacement.',
			sure_to_replace: 'Are you sure you want to apply the replacement ? The data will be moved permanently.',
			sure_to_delete: 'Are you sure you want to delete this reference ?',
			please_write_down: ' Please write down',
			delete_reference: 'Replacement is now over, you can delete reference by clicking on the following button',
			archive_ref: 'Archive reference',
			dataset: 'Datasets',
			metadata: 'Metadata',
			pipeline: 'Data prep',
			select_dataset: 'Select datasets for which you want to apply the correction',
			select_metadata: 'Select resource for which you want to apply the correction',
			select_pipeline: 'Select the data prep for which we want you want to apply the correction',
			pipeline_info: 'List of data prep where the reference is used',
			select_fields: 'For each dataset, select the fields for which you want to apply the correction',
			alert_doesnt_apply_on_metadata: "Dry run doesn't apply on metadata",
			no_sources: 'No dataset is using this reference',
			correction_from_to: 'Correction from {{from}} to {{to}}',
			fields: 'Fields',
			actions: 'Actions',
			replace_mapping: 'Replace mapping',
			replace_settings: 'Replace settings',
			replace_pipeline: 'Replace pipeline settings',
			update_metadata: 'Update metadata',
			update_metadata_description:
				'Update metadata: means the product used as a meta information for the dataset, the relation in dashboards, etc.',
		},
		info: {
			limit_dataset: 'You can only select max 50 datasets at a time',
			changes_can_be_empty:
				'It may happen that the changes are at 0, it means that our data catalog is not up to date for this dataset. A corretion will update this information.',
		},
	},
	ruleset: {
		title: 'Ruleset',
		fields: {
			generated_code: 'Generate Code Based On Name',
			convention: 'Convention',
			full_word: 'Full word',
			initial: 'Initial',
			replace_spaces: 'Replace space character',
			code: 'Code',
			mandatory_pattern: 'Mandatory pattern',
			recommanded_pattern: 'Recommended pattern',
			error_message_pattern: 'Error message related to pattern',
			min_char: 'Min character',
			max_char: 'Max character',
			name: 'Name',
		},
	},
	reapply_mapping: {
		nb_changes: 'Number of changes',
		message: {
			success: 'The mapping has been applied successfully.',
			error: 'An error occurred while applying the mapping.',
			info: 'The mapping is not applied but provided few stats. You can apply it by clicking on the button Save.',
		},
		title: 'Reapply mapping',
		description:
			'Apply your mapping to all the data in the dataset. This includes only the information present in the dataset and not the history of previous versions.',
		description_disabled: 'This dataset does not contain any data to reapply the mapping to.',
		fields: {
			dataset_fields: 'List of fields to which you wish to apply changes',
		},
		infos: {
			are_you_sure: 'Are you sure to reapply the mapping?',
			current_changes: 'Your currently have changes in your mapping. Save first before reapplying mapping.',
		},
	},
	tabs: {
		active: 'Active',
		pending: 'Pending',
		draft: 'Draft',
		to_be_approved: 'To be approved',
		inactive: 'Inactive',
		past: 'Past',
	},
	status: {
		active: 'Active',
		pending: 'Pending',
		draft: 'Draft',
		to_be_approved: 'To be approved',
		inactive: 'Past',
		pending_add: 'Pending add',
		pending_remove: 'Pending remove',
		pause: 'Pause',
		inactive: 'Inactive',
	},
};
