import { faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons';
import { getApiPrefix, referenceData, server } from 'src/utils/api-prefix.helper';
// constants
const referenceDataUrl = `${server}${getApiPrefix()}/${referenceData}/`;

export default {
	referenceRecord: 'product',
	referenceData: false,
	is_mixed: true,
	iconName: faMapMarkerAlt,
	url: `${referenceDataUrl}product?params=is_mixed`,
	path: '/products',
	urlParams: '',
	parentField: 'parent_id',
	navigateByCode: true,
	pageTitle: {
		list: 'Products and groups',
		action: {
			create: 'New product and group',
			edit: 'Edit product and group',
		},
	},
};
