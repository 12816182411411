// config
import config from 'src/config';
// utils
import { getApiPrefix, server, storage } from 'src/utils/api-prefix.helper';
// services
import DataService from 'src/utils/DataService';
const serverUrl = `${server}${getApiPrefix()}/`;

/**
 * get email ruleset list
 * @returns
 */
export function getEmailRulesetsService() {
	const split = config.api.split || 3000;
	return new DataService({
		url: `${serverUrl}${storage}/email-ruleset?limit=${split}&offset=${0}`,
		urlParams: '',
		token: true,
	});
}
/**
 * create email ruleset
 * @param payload
 * @returns
 */
export const createEmailRuleset = async (payload: any) => {
	return await new DataService({
		url: `${serverUrl}${storage}/email-ruleset`,
		urlParams: '',
		token: true,
	}).create(payload);
};

/**
 * update email ruleset
 * @param id
 * @param payload
 * @returns
 */
export const updateEmailRuleset = async (id: string, payload: any) => {
	return await new DataService({
		url: `${serverUrl}${storage}/email-ruleset`,
		urlParams: '',
		token: true,
	}).update(id, payload);
};

/**
 * get email ruleset details
 * @param id
 * @returns
 */
export function getEmailRuleset(id: string) {
	return new DataService({
		url: `${serverUrl}${storage}/email-ruleset`,
		noParams: true,
	}).get(id);
}

/**
 * bulk delete emailRuleset
 * @param payload
 * @returns
 */
export const bulkDeleteEmailRuleset = async (payload: { ids: string[] }) => {
	return await new DataService({
		url: `${serverUrl}${storage}/email-ruleset/delete`,
		urlParams: '',
	}).post(payload);
};
