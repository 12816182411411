// ant design & icons
import { faCalendarPlus } from '@fortawesome/pro-light-svg-icons';
// utils
import { referenceDataUrl } from 'src/utils/api-prefix.helper';

export default {
	referenceRecord: 'exchange-month-reference',
	referenceData: true,
	navigateByCode: true,
	iconName: faCalendarPlus,
	url: `${referenceDataUrl}exchange-month-reference`,
	urlParams: 'relation=[user]',
	path: '/exchange-month-reference',
	elasticSearchKey: 'ExchangeMonthReference',
	relatedReferences: [],
	keysMatching: [],
	pageTitle: {
		list: 'Exchange month',
		action: {
			create: 'New exchange month',
			edit: 'Edit exchange month',
		},
	},
	columns: [
		{
			headerName: 'Inactive',
			field: 'is_archived',
			type: 'boolean',
			initialHide: true,
		},
		{
			headerName: 'Name',
			field: 'name',
		},
		{
			headerName: 'Code',
			field: 'code',
		},
		{
			headerName: 'Created by',
			minWidth: 150,
			field: 'created_by_user',
			type: 'reference',
			referenceType: 'user',
		},
		{
			headerName: 'Created',
			field: 'created_at',
			type: 'datetime',
		},
		{
			headerName: 'Updated',
			field: 'updated_at',
			type: 'datetime',
		},
	],
	canCreate: true,
	canEdit: true,
	canDelete: true,
	canArchive: true,
};
