// Dependencies
import React from 'react';
// ant design & Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/pro-light-svg-icons';
// utils
import { referenceDataUrl } from 'src/utils/api-prefix.helper';
import ReferenceField from 'ui/components/ReferenceField/ReferenceField';

export default {
	referenceRecord: 'company-group',
	groupOf: 'company',
	referenceData: true,
	navigateByCode: true,
	icon: (
		<div className="fa-layers fa-fw">
			<FontAwesomeIcon icon={faBuilding} transform="up-2 left-3" />
			<FontAwesomeIcon icon={faBuilding} transform="shrink-4 down-6 right-8" />
		</div>
	),
	url: `${referenceDataUrl}company-group`,
	urlParams: 'relation=[direct_parent]',
	path: '/company-group',
	elasticSearchKey: 'Company',
	relatedReferences: [],
	keysMatching: [],
	pageTitle: {
		list: 'Company groups',
		action: {
			create: 'New company group',
			edit: 'Edit company group',
		},
	},
	columns: [
		{
			headerName: 'Inactive',
			field: 'is_archived',
			type: 'boolean',
			hide: true,
			initialHide: true,
		},
		{
			headerName: 'Name',
			field: 'name',
		},
		{
			headerName: 'Code',
			field: 'code',
		},
		{
			headerName: 'Parent',
			field: 'direct_parent',
			valueGetter: ({ data }: any) => data.direct_parent?.name || data.direct_parent?.code,
			cellRenderer: ({ data }: any) => {
				return data.direct_parent ? (
					<ReferenceField
						disabled={data.direct_parent.deleted_at}
						value={data.direct_parent.name}
						url={`/${data.direct_parent.is_group ? 'company-group' : 'company'}/${data.direct_parent.code}`}
					/>
				) : (
					'_'
				);
			},
		},
		{
			headerName: 'Created by',
			minWidth: 150,
			field: 'created_by_user',
			type: 'reference',
			referenceType: 'user',
		},
		{
			headerName: 'Created',
			field: 'created_at',
			type: 'datetime',
		},
		{
			headerName: 'Updated',
			field: 'updated_at',
			type: 'datetime',
		},
	],
	canCreate: true,
	canEdit: true,
	canDelete: true,
	canArchive: true,
	canMoveParent: true,
	parentField: 'parent_id',
};
