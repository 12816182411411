import React from 'react';
import { Tag } from 'antd';
import { ILabel } from '../types/interfaces/label.interface';
import { getIsPrivate, getColor } from 'src/ui/modules/Core/utils/label.helper';

export const recordType = 'label';

export const labelColumns = [
	{
		headerName: 'Name',
		field: 'name',
		sortable: true,
		flex: 1,
		minWidth: 120,
	},
	{
		headerName: 'Private',
		field: 'is_private',
		cellRenderer: getIsPrivate,
		sortable: true,
		flex: 1,
		minWidth: 120,
	},
	{
		headerName: 'Type',
		field: 'type',
		sortable: true,
		resizable: true,
		flex: 1,
		minWidth: 120,
		cellRenderer: ({ data }: { data: ILabel }) => {
			if (!data?.type) return '_';
			const fullLabel = labelTypes.find((el) => el.value === data.type);
			return <Tag color="default"> {fullLabel?.label || data.type || '_'} </Tag>;
		},
	},
	{
		headerName: 'Color',
		field: 'color',
		cellRenderer: getColor,
		sortable: true,
		flex: 1,
		minWidth: 120,
	},
	{
		headerName: 'Created at',
		field: 'created_at',
		type: 'datetime',
		sortable: true,
		flex: 1,
		minWidth: 120,
	},
	{
		headerName: 'Updated at',
		field: 'updated_at',
		type: 'datetime',
		sortable: true,
		flex: 1,
		minWidth: 120,
	},
];

export const labelTypes = [
	{
		value: 'dataset',
		label: 'Dataset',
	},
	{
		value: 'trade_flow',
		label: 'TradeMatrix',
	},
	{
		value: 'production',
		label: 'Production',
	},
	{
		value: 'resource_group',
		label: 'Resource Group',
	},
];
