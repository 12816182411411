import { KEYS } from 'ui/modules/Storage/types/enums/Search-Keys.enum';
import { MimeType } from 'ui/modules/Storage/types/enums/File-Types.enum';
import { ImageFileType } from 'ui/modules/Storage/types/enums/File-Image.enum';
import { getApiPrefix, server } from 'src/utils/api-prefix.helper';
import { faFileSearch } from '@fortawesome/pro-light-svg-icons';
// constants
const serverUrl = `${server}${getApiPrefix()}/`;

export default {
	page_title: 'Files',
	pageTitle: {
		list: 'Files',
	},
	iconName: faFileSearch,
	path: '/storage/files',
	apiGateway: {
		files: `${serverUrl}storage/files`,
		files_page_size: 1000,
		download_file: `download`,
		request_upload_url: `${serverUrl}storage/files/request-upload-url`,
		upload_file: `${serverUrl}storage/files/upload`,
		virtual_Folder: `${serverUrl}storage/files/virtual-folder`,
	},
	viewModeOptions: [
		{ label: 'Year', value: 'date-year' },
		{ label: 'Month', value: 'date-month' },
		{ label: 'Source', value: 'source' },
		{ label: 'File type', value: 'filetype' },
	],
	filterOption: [
		{ key: 'fileType', value: 'File type' },
		{ key: 'fileName', value: 'File name' },
		{ key: 'content', value: 'Content' },
		{ key: 'date', value: 'Date' },
		{ key: 'reference', value: 'Reference' },
	],
	fileType: [
		{ key: MimeType.EML, value: 'EML', icon: { src: ImageFileType.EML, alt: 'EML SVG' } },
		{ key: MimeType.PBIX, value: 'PBIX', icon: { src: ImageFileType.PBIX, alt: 'PBIX SVG' } },
		{ key: MimeType.PDF, value: 'PDF', icon: { src: ImageFileType.PDF, alt: 'PDF SVG' } },
		{ key: MimeType.CSV, value: 'CSV', icon: { src: ImageFileType.CSV, alt: 'CSV SVG' } },
		{ key: MimeType.PNG, value: 'PNG', icon: { src: ImageFileType.PNG, alt: 'PNG SVG' } },
		{ key: MimeType.JPG, value: 'JPG', icon: { src: ImageFileType.JPG, alt: 'JPG SVG' } },
		{ key: MimeType.JPEG, value: 'JPEG', icon: { src: ImageFileType.JPEG, alt: 'JPG SVG' } },
		{ key: MimeType.JSON, value: 'JSON', icon: { src: ImageFileType.JSON, alt: 'JSON SVG' } },
		{ key: MimeType.SHELL, value: 'SHELL', icon: { src: ImageFileType.SHELL, alt: 'SHELL SVG' } },
		{ key: MimeType.XLS, value: 'XLS', icon: { src: ImageFileType.XLS_XLSX, alt: 'XLS SVG' } },
		{ key: MimeType.XLSX, value: 'XLSX', icon: { src: ImageFileType.XLS_XLSX, alt: 'XLSX SVG' } },
		{ key: MimeType.PPT, value: 'PPT', icon: { src: ImageFileType.PPT_PPTX, alt: 'PPT SVG' } },
		{ key: MimeType.PPTX, value: 'PPTX', icon: { src: ImageFileType.PPT_PPTX, alt: 'PPTX SVG' } },
		{ key: MimeType.DOC, value: 'DOC', icon: { src: ImageFileType.DOC_DOCX, alt: 'DOC SVG' } },
		{ key: MimeType.DOCX, value: 'DOCX', icon: { src: ImageFileType.DOC_DOCX, alt: 'DOCX SVG' } },
	],
	operation: [
		{ key: 'contain', value: 'Contain' },
		{ key: 'not-contain', value: 'Not contain' },
		{ key: 'start-with', value: 'Start with' },
		{ key: 'end-with', value: 'End with' },
		{ key: 'equal', value: 'equal to' },
		{ key: 'not-equal', value: 'Not equal to' },
		{ key: 'regexp', value: 'Matches pattern' },
	],
	operator: [
		{ key: 'is', value: 'Is' },
		{ key: 'is-not', value: 'Is not' },
	],
	date_operation: [
		{ key: 'before', value: 'Is before' },
		{ key: 'after', value: 'Is After' },
		{ key: 'is-between', value: 'Is between' },
	],
	date_type: [
		{ key: 'created_at', value: 'Creation date' },
		{ key: 'updated_at', value: 'Update date' },
	],
	chart: {
		height: 150,
	},
	formItemLayout: {
		labelCol: {
			xs: { span: 12 },
			sm: { span: 12 },
			md: { span: 12 },
			lg: { span: 12 },
		},

		wrapperCol: {
			xs: { span: 12 },
			sm: { span: 12 },
			md: { span: 12 },
			lg: { span: 12 },
		},
	},
	file_upload_accept: '.png,.jpg,.jpeg,.xls,.xlsx,.pdf,.csv,.ppt,.pptx,.doc,.docx',
	dates_keys: [KEYS.DATE, KEYS.DCEQ, KEYS.DCBE, KEYS.DCAF, KEYS.DUEQ, KEYS.DUBE, KEYS.DUAF],
	years_keys: [KEYS.YEAR, KEYS.YCEQ, KEYS.YCBE, KEYS.YCAF, KEYS.YUEQ, KEYS.YUBE, KEYS.YUAF],
	months_keys: [KEYS.MONTH, KEYS.MCEQ, KEYS.MCBE, KEYS.MCAF, KEYS.MUEQ, KEYS.MUBE, KEYS.MUAF],
	supportedReferenceTypes: ['source', 'product', 'location', 'company', 'delivery-term', 'exchange', 'currency'],
};
