import React from 'react';
//Ant design & Icons
import { faBroadcastTower } from '@fortawesome/pro-light-svg-icons';
//components
import ReferenceField from 'ui/components/ReferenceField/ReferenceField';
//utils
import { referenceDataUrl } from 'src/utils/api-prefix.helper';

export default {
	referenceRecord: 'source',
	referenceData: true,
	navigateByCode: true,
	iconName: faBroadcastTower,
	url: `${referenceDataUrl}source`,
	urlParams: 'relation=[user,parent]',
	relatedReferences: [],
	keysMatching: [],
	elasticSearchKey: 'DataSource',
	path: '/sources',
	pageTitle: {
		list: 'Sources',
		action: {
			create: 'New source',
			edit: 'Edit source',
		},
	},
	columns: [
		{
			headerName: 'Inactive',
			field: 'is_archived',
			type: 'boolean',
			initialHide: true,
		},
		{
			headerName: 'External',
			field: 'is_dnext_external',
			type: 'boolean',
		},
		{
			headerName: 'Code',
			field: 'code',
		},
		{
			headerName: 'Parent',
			field: 'direct_parent',
			valueGetter: ({ data }: any) => data.direct_parent?.name || data.direct_parent?.code,
			cellRenderer: ({ data }: any) => {
				return data.direct_parent ? (
					<ReferenceField
						disabled={data.direct_parent.deleted_at}
						value={data.direct_parent.name}
						url={`/sources/${data.direct_parent.code}`}
					/>
				) : (
					'_'
				);
			},
		},
		{
			headerName: 'Created by',
			minWidth: 150,
			field: 'created_by_user',
			type: 'reference',
			referenceType: 'user',
		},
		{
			headerName: 'Created',
			field: 'created_at',
			type: 'datetime',
		},
		{
			headerName: 'Updated',
			field: 'updated_at',
			type: 'datetime',
		},
	],
	parentField: 'parent_id',
};
