// Ant design & Icons
import { faAbacus } from '@fortawesome/pro-light-svg-icons';
// utils
import { dataPrep, getApiPrefix, server } from 'src/utils/api-prefix.helper';
//services
import { getPipelineService, downloadFile } from 'ui/modules/Pipeline/utils/service.dataprep';
// constants
const serverUrl = `${server}${getApiPrefix()}/`;
const dataPrepUrl = `${serverUrl}${dataPrep}`;
export default {
	referenceData: false,
	iconName: faAbacus,
	url: `${dataPrepUrl}/`,
	getService: () => ({
		instance: getPipelineService,
	}),
	recordid: 'id', // required for duplication !
	urlParams: '',
	path: '/data-prep/pipeline',
	parentPage: {
		name: 'data-prep',
	},
	pageTitle: {
		list: 'Pipeline',
		action: {
			create: 'Pipeline',
			edit: 'Edit Pipeline',
		},
	},
	onReceiveNotification(event) {
		if (event.code === 'preparing_data')
			this._alterRowData(event.object_id, {
				last_status: 'running',
			});

		if (event.details?.direct && event.details?.task_id && event.details?.url) {
			downloadFile(event.details?.url, event.details?.task_id);
		}

		if (event.type === 'error' || event.type === 'success') {
			const newStatus = event.type;

			this._alterRowData(
				event.object_id,
				{
					last_status: newStatus,
				},
				null,
				'code'
			);
		}

		this.pushNotification(this.props.t(`modules.pipeline.messages.${event.type}.${event.code}`), event.type, null);
	},
	columns: [
		{
			headerName: 'Name',
			field: 'name',
			minWidth: 220,
			width: 400,
		},
		{
			headerName: 'Code',
			field: 'code',
			minWidth: 220,
			width: 400,
		},
		{
			headerName: 'Tag',
			field: 'tag',
			type: 'label',
			minWidth: 220,
			width: 200,
			typeParams: {
				null_value: '_',
			},
		},
		{
			headerName: 'Status',
			field: 'last_status',
			type: 'label',
			minWidth: 200,
			typeParams: {
				null_value: 'Not yet run',
			},
		},
		{
			headerName: 'Last run at',
			field: 'last_run_at',
			type: 'datetime',
			minWidth: 200,
			description: 'Last execution date of the workflow',
		},
		{
			headerName: 'Updated At',
			field: 'updated_at',
			type: 'datetime',
			minWidth: 200,
		},
		{
			headerName: 'Created At',
			field: 'created_at',
			type: 'datetime',
			minWidth: 200,
		},
	],
	canView: true,
	canCreate: true,
	canEdit: true,
	canDelete: true,
	canBulkExecute: false,
	useTab: 'general',
	canExecuteDataPrep: true,
	navigateByCode: true,
};
