import React from 'react';
// services
import { getReferenceDataByType, getReferences } from 'src/ui/modules/References/services/reference.service';
// utils
import { isEmpty, isNil, capitalize, compact, map, filter, sortBy } from 'lodash';
import { getRefsFromCache } from 'src/utils/cache.helper';
// shared configs
import config from 'src/config';
import { IHierarchyElement } from 'src/ui/modules/References/types/interfaces/reference.interface';

// get all references with data (storage case)
export const getAllReferencesData = async (cache: boolean, user: any, referenceListConsumerProps: any) => {
	if (!user) return [];
	let referencesList = await getRefsFromCache(`${user.org}.settings.references.reference_lists`);
	if (isNil(referencesList)) {
		referencesList = await getReferences();
	}
	// map all references
	const AllReferencesData = await Promise.all(
		map(referencesList, async (ref) => {
			const data = await contextOrAPIGetReference(ref.code, referenceListConsumerProps, cache);
			if (data) {
				const dataToSend = {
					key: ref.code,
					data,
					code: ref.code,
					title: `${capitalize(ref.name)}`,
					label: ref.name,
					value: ref.code,
					disabled: true,
				};
				return dataToSend;
			} else return null;
		})
	);
	return compact(AllReferencesData);
};

/**
 * Get Reference Data from Context Or from API
 * @param record
 * @param referenceListConsumerProps
 * @returns
 */
export const contextOrAPIGetReference = async (record: string, referenceListConsumerProps: any, cache: boolean) => {
	const recordType = record.replace(/-/g, '_');
	const currentConfig = (config.records as any)?.[recordType];
	if (!currentConfig) return null;
	let data = [];
	if (!referenceListConsumerProps?.isReferenceListLoaded?.(recordType) || !cache) {
		const result = await getReferenceDataByType(currentConfig);
		referenceListConsumerProps?.setReferenceListValues?.(recordType, result);
		data = result;
	} else {
		data = referenceListConsumerProps?.getReferenceListValues?.(recordType) || [];
	}

	return data || [];
};

/**
 * match reference list
 * @param referenceData
 * @param relatedRefsData
 * @param keysMatching
 * @returns
 */
export const matchReferenceList = async (data: any, relatedRefsData: any, keysMatching: any) => {
	const result = await Promise.all(
		data.map(async (item: any) => {
			const newItem = { ...item };
			await Promise.all(
				keysMatching.map(async (keyMatching: any) => {
					newItem[keyMatching.key] = await getRelatedObject(
						relatedRefsData,
						newItem[keyMatching.key],
						keyMatching.type
					);
				})
			);
			return newItem;
		})
	);
	return result;
};

/***** Matching ****/
/**
 * get related References Data
 * @param relatedReferences
 * @param referenceListConsumerProps
 * @returns
 */
export const getRelatedReferencesData = async (
	relatedReferences: any,
	referenceListConsumerProps: any,
	cache: boolean = false
) => {
	const result = await Promise.all(
		map(relatedReferences, async (ref) => {
			const data = await contextOrAPIGetReference(ref, referenceListConsumerProps, cache);
			const nameRef = ref.replace('-', ' ');
			return {
				key: ref,
				data,
				code: ref,
				title: `${capitalize(nameRef)}`,
				label: nameRef,
				value: ref,
				disabled: true,
			};
		})
	);

	const refs = compact(result);
	return refs;
};

/**
 * match one row reference
 * @param data
 * @param relatedRefsData
 * @param keysMatching
 * @returns
 */
export const matchOneRowReference = (data: any, relatedRefsData: any, keysMatching: any) => {
	const newData = { ...data };
	for (const keyMatching of keysMatching) {
		newData[keyMatching.key] = getRelatedObject(relatedRefsData, newData[keyMatching.key], keyMatching.type);
	}
	return newData;
};

/**
 * get related object
 * @param relatedRefsData
 * @param code
 * @param relation
 * @returns
 */
const getRelatedObject = (relatedRefsData: any, code: string, relation: string) => {
	const relatedRef = relatedRefsData.find((item: any) => item.key === relation);
	if (relatedRef) {
		const obj = relatedRef.data.find((itm: any) => itm.id === code);
		return obj || null;
	}
	return null;
};

/**
 * formatRelatedDataset
 * @param data
 * @returns
 */
export const formatRelatedDataset = (data: any) => {
	return map(data, (item) => {
		return {
			id: item._id,
			name: item._source.name,
		};
	});
};

/**
 * loadReferenceData
 * @param recordType
 * @param props
 * @returns
 */
export const loadReferenceData = async (
	recordType: string,
	props: any,
	type: 'SELECT' | 'TRANSFER' = 'SELECT',
	refresh = false
) => {
	const referenceConfig = (config.records as any)[recordType];
	if (!referenceConfig) return [];
	const data = await contextOrAPIGetReference(recordType, props, !refresh);
	const { parentField } = referenceConfig;
	return (data || []).map((d: any) => {
		// Tree structure
		let label = d.name;
		let filter = null;

		if (d.code) label += ` (${d.code})`;
		if (recordType === 'location' || recordType === 'location_and_group') filter = d.level_name;

		if (type === 'SELECT') {
			return {
				label,
				id: d.id,
				code: d.code,
				value: d.id,
				pId: d?.[parentField] || d?.direct_parent?.id || null,
				text: label,
				isGroup: d.is_group || false,
				filter,
			};
		}
		// for tree and transfer
		return {
			label,
			id: d.id,
			value: d.id,
			pId: d?.[parentField] || null,
			text: label,
			isGroup: d.is_group || false,
			filter,
			key: d.id,
			title: d.name,
			description: d.name,
			code: d.code,
			level_name: d.level_name,
		};
	});
};

/**
 * referenceRecordFormatting
 * @param record
 * @returns
 */
export const referenceRecordFormatting = (record: any[]) => {
	let recordType = record
		.split(/(?=[A-Z])/)
		.join('_')
		.toLowerCase();

	if (recordType === 'data_source') recordType = 'source';

	if (!(config.records as any)?.[recordType]) return;

	return recordType;
};

/**
 * fetchAllReferencesList
 * @param props
 * @returns
 */
export const fetchAllReferencesList = async (
	props: any,
	types: string[] = [],
	with_mixed_reference: boolean = false
) => {
	const preloadedData: any = {};

	if (types.length === 0) {
		Object.keys(config.records).map((item) => {
			if (
				(config.records as any)[item].referenceData ||
				(with_mixed_reference && (config.records as any)[item].is_mixed)
			) {
				types.push(item);
			}
		});
	}

	await Promise.all(
		types.map(async (type: any) => {
			let recordType = referenceRecordFormatting(type);
			preloadedData[recordType] = await loadReferenceData(recordType, props);
		})
	);

	return preloadedData;
};

/**
 *
 * @param recordType
 * @param type
 * @param keySettings
 * @returns
 */
export const getSettingsFromType = (recordType: string, type: string, keySettings: string) => {
	let settings: any = localStorage.getItem(`${keySettings}.settings.references.reference_list_sync`);
	let rules: any = {};
	try {
		settings = settings ? JSON.parse(settings) : null;
		rules = (settings || []).find((x: any) => x.code === recordType) || '{}';

		rules = JSON.parse(rules.validation_rules);
	} catch (err) {}

	return !isNil(rules) && !isEmpty(rules) ? rules[type] : null;
};

/**
 *
 * @param x
 * @param parent
 * @returns
 */
export const isCountry = (x: string, parent: any) => {
	if (x === 'COUNTRY') {
		return parent.id;
	}
	return parent.pId;
};

/**
 *
 * @param {*} newReference
 * @param {*} mode
 */
export const refreshContextListReference = (
	recordType: string,
	listNamesCodes: any[],
	newReference: any,
	props: any,
	mode: 'create' | 'edit' | 'delete'
) => {
	const oldReferenceData = [...(listNamesCodes || [])];
	let data = [];
	if (mode === 'create') {
		data = [...oldReferenceData, newReference];
	} else if (mode === 'edit') {
		data = map(oldReferenceData, (el) => {
			if (el.code === newReference.code) {
				return newReference;
			}
			return el;
		});
	} else if (mode === 'delete') {
		data = filter(oldReferenceData, (el) => el.code !== newReference.code);
	}
	props.setReferenceListValues(recordType, data);
};

/**
 * hierarchy custom code by prefix
 * @param code
 * @returns
 */
export const hierarchyCustomCodeByPrefix = (code: string) => {
	if (!/^[0-9]+$/.test(code)) {
		return [code];
	}
	const hierarchy = [];
	for (let i = 2; i <= code.length; i += 2) {
		hierarchy.push(code.substring(0, i));
	}
	return hierarchy;
};

/**
 * hierarchy by prefix for ReferenceSelect
 * @param data
 * @returns
 */
export const hierarchyByPrefixReferenceSelect = (data: IHierarchyElement[]) => {
	let indexedCodes: { [key: string]: IHierarchyElement } = {};

	data.forEach((el: IHierarchyElement) => {
		indexedCodes[el.code] = el;
	});

	let results = data.map((e: IHierarchyElement) => {
		let parentCode = e.code.slice(0, -2);
		e.parentNb = e.parentNb || 0;
		e.order = `${e.code}`;
		e.label = `${e.code} / `;

		while (parentCode.length >= 2) {
			const parent = indexedCodes[parentCode];
			if (parent) {
				e.parentNb += 1;
				e.label = `${parent.code} / ${e.label}`;
				parentCode = parentCode.slice(0, -2); // Move to the next parent
			} else {
				break;
			}
		}

		e.label = (
			<>
				<span className="parent_element"> {e.label} </span>
				{e.text}
			</>
		);

		return e;
	});
	return sortBy(results, ['order']);
};
