// dependencies
import { compact, flatMap, keys, pickBy, sortBy } from 'lodash';
// utils
import DataService from 'src/utils/DataService';
//configs
import config from 'src/config';
// interfaces
import { IDataset } from 'ui/modules/Core/types/interfaces/dataset.interface';
import { IWorkflow } from 'ui/modules/Core/types/interfaces/workflow.interface';
import { ITradeflow } from 'ui/modules/Core/types/interfaces/tradeflow.interface';
import { IResourceGroup } from 'ui/modules/OrganizationSettings/types/interfaces/resource-group.interface';
/**
 * get payload to be send  to the payload od apply tags
 * @param {*} resourceType
 * @returns
 */
export const getPayloadApplyLabel = (
	resourceType: 'dataset' | 'tradeflow' | 'workflow' | 'resource_group',
	selectedRows: IWorkflow[] | IDataset[] | ITradeflow[] | IResourceGroup[],
	tags: { checked: boolean; indeterminate: boolean }[]
) => {
	const filteredTags = pickBy(tags, (obj) => obj.checked || obj.indeterminate);
	const label_ids = keys(filteredTags);
	let resource_ids: string[] = [];
	if (resourceType === 'workflow') {
		resource_ids = compact(selectedRows.map((el) => (el as IWorkflow)?.dataset?.id || null));
	} else {
		resource_ids = compact(selectedRows.map((el) => el.id));
	}

	return { resourceType, resource_ids, label_ids };
};

/**
 * split get all api result based on total_size value
 * @param {*}
 * @returns
 */
export async function getSplitResult(
	data: {
		result: Response;
		total_size: number;
	},
	type: string,
	params: string = ''
) {
	const configRecord = config.records[type];
	if (!configRecord) return data?.result || [];
	let results: any = [];
	if (data?.result) {
		const isRef = Boolean(configRecord.referenceRecord);
		const split = config.api.split || 3000;
		const baseUrl = configRecord.url;
		const totalSize = data.total_size || split;
		const apiCallNumber = Math.max(Math.ceil(totalSize / split) - 1, 0);
		results = data.result;
		if (apiCallNumber === 0) {
			return isRef ? sortBy(results, (ref) => ref?.name?.toLowerCase()) : results;
		}
		const promises = [];
		for (let i = 1; i <= apiCallNumber; i++) {
			const url = `${baseUrl}?limit=${split}&offset=${split * i}${params}`;
			const val = new DataService({ url, urlParams: '' });
			const response = val.getAll();
			promises.push(response);
		}
		const splitResult = await Promise.all(promises);
		const flatMappedData = flatMap(splitResult.map((el) => el?.data?.result || []));
		const combinedResults = [...results, ...flatMappedData];
		return isRef ? sortBy(combinedResults, (ref) => ref?.name?.toLowerCase()) : combinedResults;
	}
	return results;
}
