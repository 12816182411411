// Dependencies
import React from 'react';
// Ant Design & Icon
import { Space, Tag } from 'antd';
import { CheckCircleTwoTone, CloseCircleOutlined } from '@ant-design/icons';
// Helpers
import _ from 'lodash';
// types
import { ILabel } from 'ui/modules/Core/types/interfaces/label.interface';
// Style
import 'src/ui/modules/Core/components/Labels/Label.sass';

/**
 *
 * @param event
 * @returns
 */
export const getIsPrivate = (event: any) => {
	if (event?.data?.organization_id) {
		return <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '14px' }} />;
	} else {
		return <CloseCircleOutlined className="icon-error" style={{ fontSize: '14px' }} />;
	}
};

/**
 *
 * @param event
 * @returns
 */
export const getColor = (event: any) => {
	return <span className="span-circle" style={{ backgroundColor: event?.data?.color || 'transparent' }}></span>;
};

/**
 * convert Hex color to rgb color
 * @param hex
 * @param alpha
 * @returns
 */
const hexToRgbA = (hex: string, alpha: number) => {
	let r = parseInt(hex.slice(1, 3), 16);
	let g = parseInt(hex.slice(3, 5), 16);
	let b = parseInt(hex.slice(5, 7), 16);

	return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

/**
 * get labels to display into ag grid col
 * @param event
 * @returns
 */
export const getLabels = (event: { value: ILabel[] }) => {
	const labels = event.value;
	return <Space className="tags">{_.map(labels, (el) => getOneLabel(el))}</Space>;
};

/**
 * get label as tag with color
 * @param label
 * @returns
 */
export const getOneLabel = (label: ILabel) => {
	if (!label.color || label.color === '#ffffff' || label.color === 'white') {
		return (
			<Tag key={label.id} color="default">
				{label.name}
			</Tag>
		);
	}
	return (
		<div
			key={label.id}
			style={{
				width: 'max-content',
				height: 'auto',
				backgroundColor: hexToRgbA(label.color, 0.1),
				color: label.color,
				border: `1px solid ${label.color}`,
				fontSize: 12,
				padding: '0px 7px',
				borderRadius: 3,
				lineHeight: 1.5715,
				marginRight: 8,
			}}
		>
			{label.name}
		</div>
	);
};
