// ant design & Icons
import { faPassport } from '@fortawesome/pro-light-svg-icons';
// utils
import { referenceDataUrl } from 'src/utils/api-prefix.helper';

export default {
	referenceRecord: 'custom-code',
	referenceData: true,
	navigateByCode: true,
	groupOf: 'custom-code',
	iconName: faPassport,
	url: `${referenceDataUrl}custom-code`,
	urlParams: 'relation=[user,direct_parent]',
	path: '/custom-codes',
	elasticSearchKey: 'CustomCode',
	relatedReferences: [],
	keysMatching: [],
	pageTitle: {
		list: 'Custom codes',
		action: {
			create: 'New custom code',
			edit: 'Edit custom code',
		},
	},
	columns: [
		{
			headerName: 'Inactive',
			field: 'is_archived',
			type: 'boolean',
			initialHide: true,
		},
		{
			headerName: 'Name',
			field: 'name',
		},
		{
			headerName: 'Product',
			field: 'product',
			type: 'reference',
		},
		{
			headerName: 'Created by',
			field: 'created_by_user',
			type: 'reference',
			referenceType: 'user',
		},
		{
			headerName: 'Created',
			field: 'created_at',
			type: 'datetime',
		},
		{
			headerName: 'Updated',
			field: 'updated_at',
			type: 'datetime',
		},
	],
	canCreate: true,
	canEdit: true,
	canDelete: true,
	canArchive: true,
	parentField: 'parent_custom_code_id',
};
