import { faLayerGroup } from '@fortawesome/pro-light-svg-icons';
import { data, getApiPrefix, server } from 'src/utils/api-prefix.helper';
// services
import { bulkDeleteCategory, getCategoryService } from 'ui/modules/Core/services/category.service';
// constants
const serverUrl = `${server}${getApiPrefix()}/`;
export default {
	referenceData: false,
	iconName: faLayerGroup,
	url: `${serverUrl}${data}/categories`,
	urlParams: 'relation=[user,parent,category_fields]',
	urlOneDatasetParams: 'relation=[user,parent,category_fields,dataset]',
	path: '/categories',
	pageTitle: {
		list: 'Categories',
		action: {
			create: 'New category',
			edit: 'Edit category',
		},
	},
	columns: [
		{
			headerName: 'Code',
			field: 'code',
		},
		{
			headerName: 'Parent',
			field: 'parent',
			type: 'reference',
			referenceType: 'category',
		},
		{
			headerName: 'Created by',
			minWidth: 150,
			field: 'created_by_user',
			type: 'reference',
			referenceType: 'user',
		},
		{
			headerName: 'Created',
			field: 'created_at',
			type: 'datetime',
		},
		{
			headerName: 'Updated',
			field: 'updated_at',
			type: 'datetime',
		},
	],
	canView: true,
	canCreate: true,
	canEdit: true,
	canDelete: true,
	parentField: 'parent_category_id',
	getService: () => ({
		instance: getCategoryService,
		bulkDelete: bulkDeleteCategory,
	}),
	navigateByCode: true,
};
